<template>
  <v-dialog
    v-model="timedBidModal"
    width="700"
    content-class="bid-modal-content"
    @keydown.esc="closeModal"
    persistent
  >
    <v-card class="pa-5" v-if="!_.isEmpty(mutableArticle) && mutableArticle.timedData">
      <v-btn class="modal-close-btn icon-with-hover" icon @click="closeModal"><v-icon>fa-times</v-icon></v-btn>
      <h2 class="pt-2 text-center">{{ $t('timed_bids_modal_headline') }}</h2>
      <p class="pt-2 mb-4 bid-modal-text"><span>{{ $t('timed_bids_info') }}</span></p>
      <p class="mb-2 bid-modal-text"><span>{{ $t('Ends at') }}</span>: {{ getFormattedDate(mutableArticle.timedData.active_until, appStoreLang, true, false) }}</p>
      <p class="mb-2 bid-modal-text">
        <span>{{ $t('Status') }}:</span>
        <span class="ml-1 status" v-if="mutableArticle.timedData.bidder_status === 'no-bid'">{{ $t('Nobody bid on this article yet. Be the first!') }}</span>
        <span class="ml-1 status green--text" v-if="mutableArticle.timedData.bidder_status === 'highest-bidder'">{{ $t('You are the highest bidder') }}</span>
        <span class="ml-1 status red--text" v-else-if="mutableArticle.timedData.bidder_status === 'outbid'">{{ $t('You are outbid, bid again') }}</span>
      </p>
      <p class="pt-2 mb-2 bid-modal-text" v-if="mutableArticle.timedData.current_hammer_price"><span>{{ $t('Current hammer price') }}</span>: {{ currency }} {{ euNumbers(mutableArticle.timedData.current_hammer_price) }}</p>
      <p class="pt-2 mb-2 bid-modal-text" v-if="mutableArticle.timedData.maximum_bid != null"><span>{{ $t('Your maximum bid') }}</span>: {{ currency }} {{ euNumbers(mutableArticle.timedData.maximum_bid) }}</p>

      <v-form ref="bidForm" v-model="isFormValid" @submit.prevent>
        <v-text-field
          type="number"
          v-model="bidValue"
          :rules="[validationRequired(), validationHigherThanLimit(this.mutableArticle.timedData.minimum_next_bid_from_user, `${this.$t('You need to bid at least')} ${this.currency} ${this.euNumbers(this.mutableArticle.timedData.minimum_next_bid_from_user)}`)]"
          :label="minimumNextBid"
          placeholder=" "
          persistent-hint
          class="input-field"
          single-line
          @keyup.enter="isFormValid ? sendBid() : {};"
        >
          <template #message="{ message }">
            {{ translateValidationIfNeeded(message) }}
          </template>
        </v-text-field>
        <div class="info-text full-width">
          <v-icon>fa-circle-info</v-icon>
          <p>{{ $t('timed_bids_legal_info') }}</p>
        </div>
        <div class="text-center mt-2">
          <v-btn
            class="btn primary-btn success"
            :disabled="mutableArticle.timedData.minimum_next_bid_from_user > bidValue || !bidValue || disabledBidButton"
            @click="sendBid"
            :loading="loading"
          >
            <v-icon class="mr-2">fa-envelope</v-icon>
            {{ $t('timed_bids_submit') }}
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'pinia'
import frontendValidations from '@/utils/rules';
import useRootStore from '@/stores/rootStore';
import GlobalEvents, { GlobalEventType } from '@/utils/globalEvents';
import { euNumbers, getFormattedDate, translateValidationIfNeeded } from '@/services/i18n';
import dispatcher from '@/api/dispatch';

export default {
  name: 'UserTimedBid',
  props: {
    auction: {},
    article: {}
  },
  data() {
    return {
      timedBidModal: false,
      getDataLink: null,
      loading: false,
      bidValue: null,
      disabledBidButton: false,
      minimum_next_bid_from_user: null,
      isFormValid: false,
      mutableArticle: this.article
    }
  },
  computed: {
    ...mapState(useRootStore, ['appLocalization', 'appStoreLang']),
    currency() {
      return this.appLocalization.currency
    },
    minimumNextBid() {
      return this.$t('Your bid' , {tCurrency: this.currency, tMinimumNextTimedBid:  euNumbers(this.mutableArticle.timedData.minimum_next_bid_from_user)})
    }
  },
  watch: {
    // evaluate every time the modal is opened
    timedBidModal(val) {
      if (val) {
        this.mutableArticle = {...this.article}
        GlobalEvents.subscribeEvent(GlobalEventType.updateArticle, this.handleAuctionDataUpdate)
      } else {
        GlobalEvents.unsubscribeEvent(GlobalEventType.updateArticle, this.handleAuctionDataUpdate)
      }
    },
    article(val) {
      this.mutableArticle = val;
    }
  },
  methods: {
    translateValidationIfNeeded,
    validationRequired: frontendValidations.required,
    validationHigherThanLimit: frontendValidations.higherThanLimit,
    euNumbers,
    getFormattedDate,
    openModal() {
      this.timedBidModal = true
      // initializing the polling here doesn't work because this.article is undefined if the modal is opened for the first time. On the second time, it works
    },
    closeModal() {
      this.timedBidModal = false
      this.$refs.bidForm.reset()
      this.mutableArticle = null
      clearInterval(this.getDataLink)
      if (this.$route.name !== 'dashboard' && this.$route.query && this.$route.query.action) this.$router.push({ name: this.$route.name, params: { id: this.$route.params.id } })
    },
    async sendBid() {
      try {
        this.loading = true
        this.disabledBidButton = true
        await dispatcher.placeTimedBid(this.auction.id, this.mutableArticle.id, this.bidValue)
        this.disabledBidButton = false
        this.loading = false
      } catch (e) {
        this.disabledBidButton = false
        this.loading = false
      } finally {
        this.bidValue = null
      }
    },
    handleAuctionDataUpdate() {
      const rootStore = useRootStore();
      const articles = rootStore.auctionsFetchedArticles[`auction_${this.auction.id}`]
      //console.log('new values ' + JSON.stringify(articles))
      if (!articles) return
      const filteredArticles = articles.filter(e => e.id === this.mutableArticle.id);
      if (filteredArticles.length > 0) {
        this.mutableArticle = filteredArticles[0];
      }
    },
  }
}
</script>

<style scoped lang="scss">

</style>
