<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <div class="bid-transactions-container pb-5">
      <div class="close-btn-wrapper" v-if="!_.isEmpty(currentAuction)">
        <v-btn class="close-btn" @click="closeModal">
          <v-icon class="close-modal-icon">fa-times</v-icon>
        </v-btn>
        <v-btn class="close-btn mt-2 sync icon-with-hover" @click="syncData" :disabled="!!error">
          <v-icon class="close-modal-icon">fa-sync</v-icon>
        </v-btn>
        <v-btn class="save-btn" :disabled="!actionName" @click="saveBids">
          <v-icon class="save-modal-icon">fa-save</v-icon>
        </v-btn>
      </div>
      <v-col sm="12" class="full-width">
        <v-row class="search-wrap">
          <v-col align="center">
            <div class="page-title my-3" v-if="$route.params.id">{{ $t('Absentee bids for auction') }} {{ currentAuction.code }} ({{ currentAuction.name }})</div>
            <div class="page-title my-3" v-else>{{ $t('Commission bids') }}</div>
          </v-col>
        </v-row>
      </v-col>
      <v-card class="main-card px-0" v-if="auctions" flat>
        <div>
          <v-row align="center" class="form-container justify-center">
            <v-col sm="12" class="full-width pb-0">
              <v-col class="main-col-wrapper" v-if="!$route.params.id">
                <v-col align="center" class="sub-col-wrapper">
                  <v-row>
                    <search-box
                      v-if="filteredAuctions && filteredAuctions.length > 0"
                      :currentAuction="currentAuction"
                      :auctions="filteredAuctions"
                      hide-create-new
                      title=""
                      @onSelectAuction="getAuctionDetail"
                      key="auction-search"
                    />
                    <div class="info-text" v-else>
                      <v-icon>fa-circle-info</v-icon>
                      <p>{{ $t('No bids are found') }}</p>
                    </div>
                  </v-row>
                </v-col>
              </v-col>
            </v-col>
            <v-progress-circular v-if="loading" class="loading-icon mt-6" indeterminate />
            <template v-else>
              <v-col sm="12" v-if="!_.isEmpty(currentAuction) && !error" class="pb-16">
                <v-row class="px-3">
                  <v-col cols="3">
                    <v-select
                      class="input-field mt-0 pa-3 bulk-actions-field"
                      :items="bulkItems"
                      :label="$t('Bulk actions')"
                      v-model="actionName"
                      hide-details
                      :disabled="!selectedItems.length"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      class="input-field mt-0"
                      :items="filterStateItems"
                      :label="$t('Filter by state')"
                      v-model="filterByState"
                      hide-details
                      @input="setFilterByState"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      class="input-field mt-0"
                      :items="filterUserItems"
                      :label="$t('Select user to process bids')"
                      v-model="filterByUser"
                      hide-details
                      clearable
                      @input="setFilterByUser"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="search"
                      append-icon="fa-magnifying-glass"
                      :label="$t('Search')"
                      hide-details
                      class="mb-3 px-3 mt-0"
                      @input="searchHandler"
                    />
                  </v-col>
                </v-row>
                <v-data-table
                  :headers="headers"
                  :items="tmpArticles"
                  :items-per-page="itemPerPage"
                  show-select
                  class="pb-16 pb-sm-0"
                  item-class="edit-article-row"
                  sort-by="created_at"
                  sort-desc
                  must-sort
                  ref="table"
                  :no-data-text="$t('No data available')"
                  :key="key"
                  @item-selected="itemSelected"
                  @toggle-select-all="allSelected"
                  dense
                  :footer-props="{
                    'items-per-page-all-text': $t('All'),
                    'items-per-page-text': $t('Rows per page'),
                    'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
                    'items-per-page-options': [25, 50, 100, 150]
                  }"
                >
                  <template #[`item.created_at`]="{ item }">
                    <span class="text-light">{{ getDate(item.created_at) }}</span>
                  </template>
                  <template #[`item.status`]="{ item }">
                    <span class="text-light"> {{ $t(commissionBidStatusNames[item.status].text) }}</span>
                  </template>
                  <template #[`item.user_data`]="{ item }">
                    <span class="text-light">{{ item.user_data.first_name }} {{ item.user_data.last_name }} ({{ item.bidder_number }})</span>
                  </template>
                  <template #[`item.number`]="{ item }">
                    <span class="text-light">{{ item.number_optional || item.number }}</span>
                  </template>
                  <template #[`item.limit`]="{ item }">
                    <span>{{ currency }}{{ euNumbers(item.limit) }}</span>
                  </template>
                  <template #[`item.commission_bid`]="{ item }">
                    <span>{{ currency }}{{ euNumbers(item.commission_bid) }}</span>
                  </template>
                  <template #[`item.bid_by_telephone`]="{ item }">
                    <v-icon v-if="!item.bid_by_telephone" class="red-color">fa-times</v-icon>
                    <v-icon v-else class="green-color-success">fa-check</v-icon>
                  </template>
                  <template #[`item.pickup`]="{ item }">
                    <v-icon v-if="!item.pickup" class="red-color">fa-times</v-icon>
                    <v-icon v-else class="green-color-success">fa-check</v-icon>
                  </template>
                </v-data-table>
              </v-col>
              <v-col sm="12" class="text-center" v-else>
                <h3 class="no-data-text" v-if="typeof error !== 'boolean'">{{ $t(error) }}</h3>
              </v-col>
            </template>
          </v-row>
        </div>
        <Footer />
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import Footer from '@/components/footer'
import SearchBox from '../articles/sections/search'
import _ from 'lodash'
import dispatcher from '@/api/dispatch';
import moment from 'moment';
import useRootStore from '@/stores/rootStore';
import { commissionBidStatusNames } from '@/utils/constants';
import { euNumbers } from '@/services/i18n';

export default {
  name: 'CommissionBids',
  components: {
    Footer,
    SearchBox,
  },
  data: function() {
    return {
      renderComponent: true,
      showProfileModal: false,
      modalPosition: false,
      currentAuction: {},
      currentArticle: {},
      headers: [
        {
          text: this.$t('Received at'),
          value: 'created_at',
          width: 220
        },
        {
          text: this.$t('Bid status'),
          value: 'status',
          width: 140
        },
        {
          text: this.$t('Bidder'),
          value: 'user_data',
          width: 150
        },
        {
          text: this.$t('Article number'),
          value: 'number',
          width: 150
        },
        {
          text: this.$t('Name'),
          value: 'name',
          width: 325
        },
        {
          text: this.$t('Limit'),
          value: 'limit',
          width: 90
        },
        {
          text: this.$t('Bid'),
          value: 'commission_bid',
          width: 90
        },
        {
          text: this.$t('Bid by telephone'),
          value: 'bid_by_telephone',
          width: 120
        },
        {
          text: this.$t('Pickup'),
          value: 'pickup',
        }
      ],
      itemPerPage: 20,
      page: 0,
      start: 0,
      end: 0,
      key: 0,
      error: '',
      articles: [],
      tmpArticles: [],
      loading: false,
      showCommissionBids: true,
      auctions: null,
      articlesList: null,
      articleId: null,
      filterByState: 'pending',
      filterByUser: null,
      users: null,
      search: '',
      filterStateItems: null,
      filterUserItems: null,
      bulkItems: [],
      selectedItems: [],
      actionName: null,
      commissionBidStatusNames
    }
  },
  computed: {
    ...mapState(useRootStore, ['userProfile', 'appLocalization', 'appSettings', 'appStoreLang', 'auctionsFetchedArticles', 'adminDataChange']),
    currency() {
      return this.appLocalization.currency
    },
    filteredAuctions() {
      // add commission_bids_enabled
      return this.auctions.filter(el => el.type === 'live' && moment().diff(el.active_until, 'seconds') <= 0 && el.catalogue_enabled !== 'disabled' && el.commission_bids_enabled)
    }
  },
  async created() {
    await this.getAuctions()
    if (this.$route.params.id) {
      this.loading = true
      this.currentAuction = this.auctions.find(el => el.id === +this.$route.params.id)
      if (this.currentAuction) {
        this.getAuctionDetail(this.currentAuction.id)
      } else {
        this.error = this.$t("This auction doesn't exist")
        this.loading = false
      }
    }
  },
  methods: {
    ...mapActions(useRootStore, ['updateUserProfile', 'getArticles', 'SET_TEMP_ALERT', 'SET_AUCTIONS_FETCHED_ARTICLES', 'REMOVE_AUCTIONS_FETCHED_ARTICLE']),
    euNumbers,
    changePage (el) {
      this.page = el
      this.$refs.table.$el.scrollIntoView({ behavior: 'smooth' })
    },
    searchHandler: _.debounce(function () {
      this.setFilterByState()
      if (this.search) {
        this.tmpArticles = this.tmpArticles.filter(el => (el.number && el.number.toString().includes(this.search)) ||
          (el.number_optional && el.number_optional.includes(this.search)) ||
          el.name.toLowerCase().includes(this.search.toLowerCase()) ||
          (el.name_i18n && el.name_i18n[this.appStoreLang] && el.name_i18n[this.appStoreLang].toLowerCase().includes(this.search.toLowerCase())) ||
          (el.user_data && el.user_data.first_name.toLowerCase().includes(this.search.toLowerCase())) ||
          (el.user_data && el.user_data.last_name.toLowerCase().includes(this.search.toLowerCase())))
      } else {
        this.setFilterByState()
      }
      this.setUserItems(this.tmpArticles)
    }, 500),
    getArticlesData() {
      if (this.search) {
        this.searchResult = []
        const tableSort = [...this.headers]
        let articles = [...this.tmpArticles]
        articles.forEach(el => {
          tableSort.forEach(val => {
            let str = ''
            if (val.value === 'number') {
              str = el[val.value] || el.number_optional
            } else if (val.value === 'user_data') {
              str = `${el.user_data.first_name} ${el.user_data.last_name}`
            } else if (this.getFieldName(el, val.value).includes('i18n')) {
              str = el[this.getFieldName(el, val.value)][this.appStoreLang.toLowerCase()]
            } else {
              str = el[val.value]
            }
            if (str && str.toString().toLowerCase().includes(this.search.toLowerCase()) && this.searchResult.map(({ id }) => id).indexOf(el.id) === -1) {
              this.searchResult.push(el)
            }
          })
        })
        this.tmpArticles = this.searchResult
      } else {
        this.tmpArticles = [...this.articles]
      }
    },
    getFieldName(obj, field) {
      let langTarget
      if (typeof obj[`${field}_i18n`] === 'string' && obj[`${field}_i18n`].length > 0) {
        langTarget = JSON.parse(obj[`${field}_i18n`])
      } else {
        langTarget = obj[`${field}_i18n`]
      }
      if (langTarget && Object.keys(langTarget).length > 0 && langTarget[this.appStoreLang]) {
        return `${field}_i18n`
      }
      return field
    },

    async getAuctionDetail(auctionID) {
      this.loading = true
      this.page = 0
      try {
        const data = await dispatcher.getAllCommissionBids(auctionID, true);
        const articlesData = await dispatcher.getAuctionArticles(auctionID)

        const bids = data.commission_bids.map(el => {
          const target = articlesData.find(article => article.id === el.articles_id)
          return {
            ...target,
            ...el
          }
        })

        this.error = null
        this.currentAuction.id = auctionID
        this.articles = [...bids]
        this.tmpArticles = [...bids]
        this.setStatusItems(this.tmpArticles)
        this.setFilterByState()
        this.loading = false

        this.key++
      } catch (e) {
        this.loading = false
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting the commission bids. Please try again later') })
        this.currentAuction.id = {}
        this.articles = []
        this.tmpArticles = []
        this.error = true
      }
    },
    setStatusItems(articles) {
      const pendingLength = articles.filter(el => el.status === 'pending').length
      const acceptedLength = articles.filter(el => el.status === 'accepted').length
      const declinedLength = articles.filter(el => el.status === 'declined').length
      const allLength = articles.length
      this.filterStateItems = [
        { text: `${this.$t('commissionBidStatus.pending')} (${pendingLength})`, value: 'pending' },
        { text: `${this.$t('commissionBidStatus.accepted')} (${acceptedLength})`, value: 'accepted' },
        { text: `${this.$t('commissionBidStatus.declined')} (${declinedLength})`, value: 'declined' },
        { text: `${this.$t('commissionBidStatus.all')} (${allLength})`, value: 'all' },
      ]
    },
    setUserItems(articles) {
      const userIds = _.uniq(_.map(articles.map(({ user_id: userId }) => userId)))
      this.filterUserItems = userIds.map(el => {
        const length = articles.filter(bid => bid.user_id === el).length
        const { user_data: userData } = articles.find(bid => bid.user_id === el)
        return {
          text: `${userData.first_name} ${userData.last_name} (${length})`,
          value: userData.id,
          length
        }
      }).sort((a, b) => b.length - a.length)
    },
    setBulkItems() {
      const userIds = _.uniq(_.map(this.selectedItems.map(({ user_id: userId }) => userId)))
      this.bulkItems = [
        { text: this.$t('Set pending'), value: 'pending' },
        { text: this.$t('Accept'), value: 'accepted', disabled: userIds.length > 1 },
        { text: this.$t('Decline'), value: 'declined', disabled: userIds.length > 1 },
        { text: this.$t('Delete'), value: 'delete' }
      ]
      if (!this.selectedItems.length || (userIds.length > 1 && (this.actionName === 'accepted' || this.actionName === 'declined'))) this.actionName = null
    },
    getArticleDetail(id) {
      if (id) {
        const target = this.articles.filter(el => el.number === id || el.number_optional === id)
        this.tmpArticles = [...target]
      } else {
        this.tmpArticles = [...this.articles]
      }
    },

    async getAuctions() {
      try {
        const data = await dispatcher.getAllAuctions();
        this.auctions = data
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting the auctions. Please try again later') });
        this.error = true;
      }
    },

    changePerPage(item) {
      this.itemPerPage = item
      this.page = 0
      this.getArticlesData()
      this.setUserItems(this.tmpArticles)
    },

    getDate(date) {
      if (!date) return
      const d = new Date(date);
      const ye = d.getFullYear()
      const mo = d.toLocaleString(this.appStoreLang || 'de', { month: 'long', timeZone: 'UTC' });
      const da = d.getDate()
      const h = d.getHours()
      const m = d.getMinutes()
      const s = d.getSeconds()
      const dayPrefix = da === 1 ? 'st' : da === 2 ? 'nd' : da === 3 ? 'rd' : 'th'
      return this.appStoreLang === 'en' ? `${mo} ${da}${dayPrefix}, ${ye} ${h}:${m <= 9 ? '0' + m : m}` : `${da}. ${mo}, ${ye} ${h}:${m <= 9 ? '0' + m : m}:${s <= 9 ? '0' + s : s}`
    },

    async syncData() {
      await this.getAuctionDetail(this.currentAuction.id)
      this.getArticleDetail(this.articleId)
    },

    async saveBids() {
      try {
        if (this.actionName === 'delete') {
          await dispatcher.removeCommissionBids(this.currentAuction.id)
        } else {
          const commissionBids = []
          this.selectedItems.forEach(el => {
            commissionBids.push({
              id: el.id,
              status: this.actionName
            })
          })
          await dispatcher.updateCommissionBids(this.currentAuction.id, {
            commission_bids: commissionBids,
            notify_bidder: true
          })
        }
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Values changed successfully') })
        await this.syncData()
        this.actionName = null
        this.selectedItems = []
        // this.filterByState = 'pending'
        // this.filterByUser = null
        // this.search = null
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error changing values') })
      }
    },
    setFilterByState() {
      this.getArticlesData()
      if (this.filterByState === 'all') {
        this.tmpArticles = [...this.articles]
      } else {
        this.tmpArticles = this.articles.filter(el => el.status === this.filterByState)
      }
      this.filterByUser = null
      this.setUserItems(this.tmpArticles)
    },
    setFilterByUser() {
      this.getArticlesData()
      if (this.filterByState === 'all') {
        this.tmpArticles = [...this.articles]
      } else {
        this.tmpArticles = this.articles.filter(el => el.status === this.filterByState)
      }
      if (this.filterByUser) {
        this.tmpArticles = this.tmpArticles.filter(el => el.user_id === this.filterByUser)
      } else {
        this.setUserItems(this.tmpArticles)
      }
    },
    itemSelected(val) {
      if (val.value) {
        this.selectedItems.push(val.item)
      } else {
        const idx = this.selectedItems.findIndex(el => el.id === val.item.id)
        this.selectedItems.splice(idx, 1)
      }
      this.setBulkItems()
    },
    allSelected(val) {
      this.selectedItems = val.value ? [...val.items] : []
      this.setBulkItems()
    },
    closeModal() {
      this.$router.push({ name: 'Auctions' })
    },
    pushUpdate(data, target) {
      if (data.entity === 'article') {
        const cachedData = [...this[target]]
        if (cachedData) {
          if (data.type === 'update') {
            this[target] = cachedData.map(article => {
              if (article.articles_id === data.data.id) {
                return {
                  ...article,
                  ...data.data
                }
              }
              return article
            })
          } else if (data.type === 'delete') {
            const idx = cachedData.findIndex(el => el.articles_id === data.data.id)
            cachedData.splice(idx, 1)
            this.REMOVE_AUCTIONS_FETCHED_ARTICLE({
              auctionID: data.data.auction_id,
              article: data.data
            })
            this[target] = cachedData
          } else if (data.type === 'create') {
            cachedData.push(data.data)
            this.ADD_AUCTIONS_FETCHED_ARTICLE({
              auctionID: data.data.auction_id,
              article: data.data
            })
            this[target] = cachedData
          }
        }
      }
    }
  },
  watch: {
    adminDataChange: {
      deep: true,
      handler(val) {
        this.pushUpdate(val, 'articles')
        this.pushUpdate(val, 'tmpArticles')
        this.getArticlesData()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.page-title {
  font-size: 24px;
  line-height: 25px;
  font-weight: 400;
  margin-top: 30px;
}
.article-name {
  font-size: 12px;
  -webkit-line-clamp: 1;
  @media (max-width: 968px) {
    white-space: pre-wrap;
    -webkit-line-clamp: unset;
  }
}
.bulk-actions-field {
  width: 400px;
  max-width: 100% !important;
}
</style>
