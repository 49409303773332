<template>
  <v-dialog
    class="lighten-5 guest-forms"
    id="container"
    v-model="resendActivationEmailModal"
    @keydown.esc="signInAccount"
    max-width="600"
    persistent
  >
    <v-card class="px-8 py-4">
      <v-btn class="modal-close-btn icon-with-hover" icon @click="signInAccount"><v-icon>fa-times</v-icon></v-btn>
      <v-form align="center" class="form-container" ref="resendActivationEmailForm" v-model="isFormValid" @submit.prevent>
        <v-col align="center">
          <h2 class="form-title">{{ $t('Request activation email') }}</h2>
          <div class="form-title mt-9 text-left pl-3">{{ $t('Please enter your email address.\n Then you will receive an email with the account activation link') }}</div>
          <v-col class="form-wrapper pa-0 mt-5">
            <v-col align="center">
              <v-tooltip :value="isShowErrorTooltip('email')" v-model="showMessage" :r="false" :right="toolTipPosition === 'right'" :bottom="toolTipPosition === 'bottom'" :nudge-left="toolTipPosition === 'bottom' ? 15 : 0" :content-class="`input-tooltip ${toolTipPosition === 'bottom' ? 'mobile-show-bottom' : ''}`">
                <template #activator="{ on }">
                  <v-text-field
                    v-model="email"
                    :label="$t('Email')"
                    :rules="[validationRequired(), validationEmail()]"
                    style="cursor:auto"
                    class="input-field"
                    :class="{'error-border': showMessage}"
                    single-line
                    @keyup.enter="isFormValid ? requestResendEmail() : {};"
                  >
                    <template #message="{ message }">
                      {{ $t(message) }}
                    </template>
                  </v-text-field>
                </template>
                <span>{{ message }}</span>
              </v-tooltip>
            </v-col>
            <div class="info-text full-width mb-4">
              <v-icon>fa-info-circle</v-icon>
              <p>
                {{ $t('Please not that this cannot be used if you signed in with social accounts (i.e. Google and Facebook)') }}
              </p>
            </div>
          </v-col>
          <v-col align="center" class="no-padding-bottom">
            <v-btn
              :key="appStoreLang"
              :disabled="!isFormValid"
              height="50"
              class="btn primary-btn reset-password-btn"
              @click="requestResendEmail"
              :loading="loading"
            >
              {{ $t('Resend activation email') }}
            </v-btn>
          </v-col>
        </v-col>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { timeouts } from '@/utils/constants';
import frontendValidations from '@/utils/rules';
import useRootStore from '@/stores/rootStore';
import dispatcher from '@/api/dispatch';

export default {
  data: () => ({
    resendActivationEmailModal: false,
    email: null,
    showMessage: false,
    message: '',
    toolTipPosition: 'right',
    emailError: false,
    isFormValid: false,
    loading: false
  }),
  computed: {
    ...mapState(useRootStore, ['appSettings', 'appStoreLang']),
  },
  methods: {
    validationEmail: frontendValidations.email,
    validationRequired: frontendValidations.required,
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'CLEAR_ALERT', 'SET_TEMP_ALERT']),
    isShowErrorTooltip(fieldName) {
      if (!this.showMessage || !this.errorField) {
        return false;
      }
      return fieldName === this.errorField;
    },
    signInAccount () {
      this.$refs.resendActivationEmailForm.reset()
      this.$router.push({ name: 'login' })
      this.closeModal()
    },
    async requestResendEmail() {
      let resetData = {
        email: this.email
      };

      // Call resend password email request
      try {
        this.loading = true
        await dispatcher.resendActivationEmail(resetData);

        this.$swal.fire({
          position: 'center',
          icon: 'success',
          title: this.$t('Almost done'),
          text: this.$t('A new account activation email has been sent'),
          showConfirmButton: false,
          timer: timeouts.closeSweetalert
        })
        this.signInAccount()
      } catch (e) {
        if (e.data?.error && e.data.data[0].errorShortText === 'email.could.not.be.sent') {
          if (e.data?.error) {
            this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t("An error occured. Please contact the auction house for more details") })
          }
        }
      } finally {
        this.loading = false
      }
    },
    backToSignIn() {
      this.$router.push('/login')
    },
    backToSignUp() {
      this.$router.push('/registration')
    },
    showTooltip (message) {
      // Get width
      if (window.innerWidth < 968) {
        this.toolTipPosition = 'bottom'
      } else {
        this.toolTipPosition = 'right'
      }

      this.message = message;
      this.showMessage = true;

      // Hide after 5s
      setTimeout(() => {
        this.showMessage = false
      }, 5000)
    },
    openModal() {
      this.resendActivationEmailModal = true
    },
    closeModal() {
      this.resendActivationEmailModal = false
    }
  }
}
</script>

<style>
</style>
