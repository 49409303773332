<template>
  <v-card
    class="connected-users-modal px-4 pt-4"
    :class="{ active: dialog }"
    @keydown.esc="closeModal"
    persistent
  >
    <v-btn class="close-btn icon-with-hover" icon @click="closeModal"><v-icon>fa-times</v-icon></v-btn>
    <v-card-title class="justify-center pb-0">
      <span class="headline">{{ $t('Connected users') }}</span>
    </v-card-title>

    <v-text-field
      v-model="search"
      append-icon="fa-magnifying-glass"
      :label="$t('Search')"
      hide-details
      class="mb-3"
      @input="searchHandler"
    />

    <v-data-table
      :headers="headers"
      :items="tmpUsers"
      :items-per-page="itemPerPage"
      :page.sync="page"
      class="users-table"
      item-key="subId"
      :item-class="rowClasses"
      mobile-breakpoint="100"
      sort-by="last_name"
      :no-data-text="$t('No data available')"
      dense
      :footer-props="{
        'items-per-page-all-text': $t('All'),
        'items-per-page-text': $t('Rows per page'),
        'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
        'items-per-page-options': []
      }"
    >
      <template #[`item.bidder_number`]="{ item }">
        <span v-if="currentAuction && getUserBidderNumber(item)">{{ getUserBidderNumber(item) }} {{ item.role !== 'user' ? `(${$t(item.role)})` : '' }}</span>
        <span v-else>--- {{ item.role !== 'user' ? `(${$t(item.role)})` : '' }}</span>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-icon small class="icon-with-hover" @click="clickUser(item)">fa-external-link-alt</v-icon>
        <v-icon small class="icon-with-hover ml-2" @click="clickBlock(item)" :disabled="item.role === 'admin'">fa-sign-out-alt</v-icon>
      </template>
    </v-data-table>
    <admin-disconnect-user-modal
      @submit="submitBlock"
      @resetUserId="selectedUserId = null"
      ref="adminDisconnectUserModal"
    />
  </v-card>
</template>

<script>
import {mapState} from 'pinia'
import AdminDisconnectUserModal from '@/components/modals/admin-disconnect-user-modal'
import _ from 'lodash';
import { BidderNumberType } from '@/utils/constants';
import useAuctionStore from '@/stores/auctionStore';
import useRootStore from '@/stores/rootStore';
import dispatcher from '@/api/dispatch';

export default {
  name: 'ConnectedUsersModal',
  components: {
    AdminDisconnectUserModal
  },
  data() {
    return {
      dialog: false,
      itemPerPage: 20,
      page: 0,
      tmpUsers: [],
      search: '',
      selectedUserId: null,
      headers: [
        {
          text: this.$t('Last name'),
          align: 'left',
          sortable: true,
          value: 'last_name'
        },
        {
          text: this.$t('First name'),
          sortable: true,
          value: 'first_name'
        },
        {
          text: this.$t('Bidder number'),
          sortable: false,
          value: 'bidder_number'
        },
        {
          text: this.$t('id'),
          sortable: false,
          value: 'id'
        },
        {
          text: this.$t('Session id'),
          sortable: false,
          value: 'sessionId'
        },
        {
          text: this.$t('Actions'),
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    ...mapState(useAuctionStore, ['currentAuction']),
    ...mapState(useRootStore, ['userProfile', 'clientList', 'SET_TEMP_ALERT']),
  },
  mounted() {
    this.countUserSessions();
  },
  watch: {
    clientList: {
      deep: true,
      handler() {
       this.countUserSessions();
      }
    }
  },
  methods: {
    countUserSessions() {
      let clientListFiltered = [];
      for (const clientListEntry of this.clientList) {
        const idx = clientListFiltered.findIndex(e => e.sessionId === clientListEntry.sessionId);
        if (idx !== -1) {
          clientListFiltered[idx].session_count += 1;
        } else {
          clientListFiltered.push({...clientListEntry, session_count: 1});
        }
      }
      for (const clientListEntry of clientListFiltered) {
        if (clientListEntry.session_count > 1) {
          clientListEntry.sessionId += ` (${clientListEntry.session_count}x)`
        }
      }
      this.tmpUsers = clientListFiltered.map((item, i) => ({ ...item, subId: i + 1 }))
    },
    getUserBidderNumber(user) {
      if (!user.userToAuctionUser) return false
      const auctionUserObjects = user.userToAuctionUser.filter(el => this.currentAuction && el.auction_id === this.currentAuction.id && ( el.type === BidderNumberType.live || el.type === BidderNumberType.commissionBid))
      if (auctionUserObjects.length > 0) {
        const liveBidderNumbers = auctionUserObjects.filter(e => e.type === BidderNumberType.live)
        const commissionBidBidderNumbers = auctionUserObjects.filter(e => e.type === BidderNumberType.commissionBid)
        if (!_.isEmpty(liveBidderNumbers) && !_.isEmpty(commissionBidBidderNumbers)) {
          return `${liveBidderNumbers[0].bidder_number} (${this.$t('Absentee bid')} ${commissionBidBidderNumbers[0].bidder_number})`
        } else if (!_.isEmpty(liveBidderNumbers)) {
          return liveBidderNumbers[0].bidder_number
        }
        else if (!_.isEmpty(commissionBidBidderNumbers)) {
          return commissionBidBidderNumbers[0].bidder_number
        } else {
          return false
        }
      } else {
        return false
      }
    },
    openModal() {
      this.tmpUsers = [...this.clientList]
      this.dialog = true
    },
    closeModal() {
      this.search = ''
      this.dialog = false
    },
    rowClasses(item) {
      if (item.role === 'admin') return 'text-bold'
    },
    clickUser(user) {
      const routeData = this.$router.resolve({ name: 'AdminUserProfile', params: { id: user.id } });
      window.open(routeData.href, '_blank');
    },
    clickBlock(item) {
      this.selectedUserId = item.id
      this.$refs.adminDisconnectUserModal.openModal()
    },
    async submitBlock(block) {
      try {
        await dispatcher.disconnectUser(this.selectedUserId, block)
        if (block) {
          this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('User disconnected and blocked successfully. Please use the "users" section to re-enable') })
        } else {
          this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('User disconnected successfully') })
        }
        // this.$store.commit('SET_CLIENT_DATA', this.clientList.filter(el => el.id !== this.selectedUserId))
        this.$refs.adminDisconnectUserModal.closeModal()
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error disconnecting the user') })
      }
    },
    searchHandler: _.debounce(function () {
      if (this.search) {
        this.tmpUsers = this.clientList.filter(el =>
          (this.getUserBidderNumber(el) && this.getUserBidderNumber(el).toString().includes(this.search)) ||
          el.first_name.toLowerCase().includes(this.search.toLowerCase()) ||
          el.last_name.toLowerCase().includes(this.search.toLowerCase())
        )
      } else {
        this.tmpUsers = [...this.clientList]
      }
    }, 500),
  }
}
</script>

<style scoped lang="scss">
.btn {
  width: 140px;
}
.connected-users-modal {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  min-height: 100vh;
  transform: translate(100%, 0);
  padding-bottom: 45px !important;
  transition: all .4s ease;
  .v-data-table {
    max-height: calc(100vh - 180px);
    overflow: auto;
  }
  .vue-ads-flex {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
  }
  &.active {
    transform: translate(0, 0);
  }
}
</style>
