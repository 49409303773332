<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <div class="user-profile-modal">
      <v-card class="user-profile-container user-catalogue">
        <div class="close-btn-wrapper">
          <v-btn class="close-btn" @click="close">
            <v-icon class="close-modal-icon">fa-times</v-icon>
          </v-btn>
          <v-btn class="send-btn" @click="sendBids" v-if="!disabledSendBtn && !validbids.length>0" :loading="loading">
            <v-icon class="save-modal-icon mr-1">fa-save</v-icon>
            <span>{{ $t('Save') }}</span>
          </v-btn>
        </div>
        <v-card-text class="main-card">
          <v-container>
            <div>
              <div class="scroll-anchor" ref="anchor" />
              <v-row align="center" class="form-container justify-center flex-column">
                <v-col sm="12" class="full-width">
                  <h2 class="page-title text-center">{{ $t('My Wishlist') }}</h2>
                  <v-col class="main-col-wrapper" v-if="!preselectedAuction || localySelected">
                    <v-col align="center" class="sub-col-wrapper">
                      <v-row>
                        <search-box
                          v-if="auctions && auctions.length > 0"
                          :currentAuction="currentAuction"
                          :auctions="auctions.filter(el => el.catalogue_enabled)"
                          hide-create-new
                          title=""
                          @onSelectAuction="selectAuction"
                          show-auction-type
                        />
                      </v-row>
                    </v-col>
                  </v-col>
                </v-col>
                <v-progress-circular v-if="auctionLoading" class="loading-icon mt-6" indeterminate />
                <template v-else>
                  <h3 class="auction-title" v-if="selectedAuctionData && !_.isEmpty(wishList)">{{ auctionName(selectedAuctionData) }}</h3>
                  <h4 class="auction-date" v-if="selectedAuctionData && !_.isEmpty(wishList) && selectedAuctionData.start_at">{{ selectedAuctionDate }}</h4>
                  <span class="auction-date mt-4" v-if="selectedAuctionData && !_.isEmpty(wishList) && selectedAuctionData.no_shipping">{{ $t('Please note') }}, {{ $t('that for this auction,shipping is not possible. All bought articles need to be picked up') }}</span>
                  <v-col sm="12" v-if="!_.isEmpty(wishList)">
                    <v-text-field
                      v-model="search"
                      append-icon="fa-magnifying-glass"
                      :label="$t('Search')"
                      hide-details
                      class="mb-3"
                      @input="searchHandler"
                      clearable
                    />
                    <div class="catalogue list-view">
                      <v-form class="catalogue__list" ref="bidForm">
                        <div
                          class="catalogue__item"
                          :class="{ highlight: article.is_highlight }"
                          v-for="(article, i) in articlesView"
                          :key="article.id"
                        >
                          <div class="catalogue__item-header">
                            <p class="catalogue__item-highlight" v-if="article.is_highlight">{{ $t('Highlight') }}</p>
                            <v-tooltip top allow-overflow>
                              <template #activator="{ on, attrs }">
                                <svg
                                  v-bind="attrs"
                                  v-on="on"
                                  height="30px"
                                  width="30px"
                                  :fill="globalTheme === 'dark' ? '#ffffff' : '#000000'"
                                  xmlns="http://www.w3.org/2000/svg"
                                  data-name="Layer 101"
                                  viewBox="0 0 100 100" x="0px" y="0px"
                                  class="catalogue__item-shipping icon-with-hover mr-2"
                                  v-if="article.no_shipping || selectedAuctionData.no_shipping"
                                >
                                  <path d="M50,90A40,40,0,1,0,10,50,40,40,0,0,0,50,90Zm0-73.23a33.1,33.1,0,0,1,21,7.46l-4.39,4.39a.63.63,0,0,1,.16.26L69,35.76,75.77,29A33.23,33.23,0,0,1,29,75.77l4.13-4.13h-2.4a.76.76,0,0,1-.75-.75V65.21L24.23,71A33.23,33.23,0,0,1,50,16.77Z" /><path d="M68.5,70.14V39.56H54v8.87a.76.76,0,0,1-.75.75H46.79a.76.76,0,0,1-.75-.75V39.56H31.5V70.14Z" /><polygon points="31.79 38.05 46.08 38.05 46.53 29.86 34.5 29.86 31.79 38.05" /><polygon points="53.92 38.05 68.21 38.05 65.5 29.86 53.47 29.86 53.92 38.05" /><polygon points="52.42 38.05 51.96 29.86 48.04 29.86 47.58 38.05 52.42 38.05" /><rect x="47.54" y="39.56" width="4.92" height="8.13" />
                                </svg>
                              </template>
                              <span>{{ $t('No shipping possible') }}</span>
                            </v-tooltip>
                            <v-tooltip top allow-overflow>
                              <template #activator="{ on, attrs }">
                                <div
                                  v-bind="attrs"
                                  v-on="on"
                                  class="catalogue__item-inquiry icon-with-hover mr-2 input-field-bigger-icon"
                                  @click="openInquiryModal(article)"
                                >
                                  <v-icon>fa-question-circle</v-icon>
                                </div>
                              </template>
                              <span>{{ $t('Lot inquiry') }}</span>
                            </v-tooltip>
                            <div class="catalogue__item-wish icon-with-hover input-field-bigger-icon" @click="removeFromWishList(article)">
                              <v-icon>fa-trash</v-icon>
                            </div>
                          </div>
                          <div class="catalogue__item-body">
                            <div class="catalogue__show-image" v-show="article.offensive && !showImages[`article${article.id}`]">
                              <v-btn class="btn wrap-text" @click.stop="showImageToggle(article.id)">{{ $t('offensive_article_button_show_image') }}</v-btn>
                            </div>
                            <div
                              class="catalogue__image"
                              ref="carousel"
                              v-if="article.images && article.images.length && article.images.length <= 1"
                              v-show="showImages[`article${article.id}`] || !article.offensive"
                              @click="e => selectImage(e, article.images, 0)"
                            >
                              <img :src="article.images[0] ? article.images[0].image : ''"/>
                            </div>
                            <div class="catalogue__slider" v-else-if="article.images && article.images.length" v-show="showImages[`article${article.id}`] || !article.offensive">
                              <div class="custom-arrow slick-arrow slick-next" @click.stop="$refs.carousel[i].next()">
                                <v-icon class="arrow-left">fa-angle-right</v-icon>
                              </div>
                              <div class="custom-arrow slick-arrow slick-prev" @click.stop="$refs.carousel[i].prev()">
                                <v-icon class="arrow-right">fa-angle-left</v-icon>
                              </div>
                              <VueSlickCarousel ref="carousel" lazyLoad="ondemand" :arrows="false" :key="article.images.length" :dots="false">
                                <div
                                  class="image-item"
                                  v-for="(itm, idx) in article.images"
                                  :key="itm.articleNumber"
                                  @click.stop="e => selectImage(e, article.images, idx)"
                                >
                                  <img class="article-image" :src="itm.image" :style="{'max-height': isMobile ? '114px' : '156px'}">
                                </div>
                              </VueSlickCarousel>
                            </div>
                            <div class="catalogue__image cursor-pointer" ref="carousel" v-else />
                            <div class="catalogue__item-content" :style="{ width: article.images && article.images.length ? '' : '100%' }">
                              <h3 class="catalogue__name">{{ articleTitle(article) }}</h3>
                              <v-row class="my-3 px-3" justify="space-between" align="center">
                                <v-col cols="12" class="pt-0 pb-0 pl-0">
                                  <div class="catalogue__meta mt-0">
                                    <div class="catalogue__meta-item">
                                      <p class="catalogue__meta-name">{{ $t('Article') }}</p>
                                      <h5 class="catalogue__meta-value">#{{ article.number_optional || article.number }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-if="article.limit">
                                      <p class="catalogue__meta-name" v-if="selectedAuctionData.type === 'timed'">{{ $t('Start price') }}</p>
                                      <p class="catalogue__meta-name" v-else>{{ $t('Limit') }}</p>
                                      <h5 class="catalogue__meta-value">{{ currency }} {{ euNumbers(article.limit) }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-else-if="article.buy_price && selectedAuctionIsSale">
                                      <p class="catalogue__meta-name">{{ $t('Buy price') }}</p>
                                      <h5 class="catalogue__meta-value">{{ currency }} {{ euNumbers(article.buy_price) }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type !== 'timed' && article.estimated_price && (selectedAuctionData.catalogue_enabled === 'enabled' || selectedAuctionData.catalogue_enabled === 'enabled_with_results_and_estimations')">
                                      <p class="catalogue__meta-name">{{ $t('Estimated price') }}</p>
                                      <h5 class="catalogue__meta-value">{{ currency }} {{ article.estimated_price }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type === 'timed' && article.timedData">
                                      <p class="catalogue__meta-name">{{ $t('Current price') }}</p>
                                      <h5 class="catalogue__meta-value" v-if="article.timedData.current_hammer_price">{{ currency }} {{ euNumbers(article.timedData.current_hammer_price) }}</h5>
                                      <h5 class="catalogue__meta-value" v-else>-</h5>
                                    </div>
                                    <!--                                  <div class="catalogue__meta-item" v-if="selectedAuctionData.type === 'timed' && article.timedData && article.timedData.active_from">-->
                                    <!--                                    <p class="catalogue__meta-name" v-translate>Starts at</p>-->
                                    <!--                                    <h5 class="catalogue__meta-value">{{ getFormattedDate(article.timedData.active_from, appStoreLang) }}</h5>-->
                                    <!--                                  </div>-->
                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type === 'timed' && article.timedData && article.timedData.active_until">
                                      <p class="catalogue__meta-name">{{ $t('Ends at') }}</p>
                                      <h5 class="catalogue__meta-value">{{ getFormattedDate(article.timedData.active_until, appStoreLang, false, false) }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type === 'timed' && article.timedData && article.timedData.active_until && getRemainingTime(article.timedData.active_until, redrawRemainingTime)">
                                      <p class="catalogue__meta-name">{{ $t('Remaining time') }}</p>
                                      <h5 class="catalogue__meta-value">{{ getRemainingTime(article.timedData.active_until, redrawRemainingTime) }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type === 'live' && selectedAuctionData.status !== 'preparing' && (selectedAuctionData.catalogue_enabled === 'enabled_with_results_and_estimations' || selectedAuctionData.catalogue_enabled === 'enabled_with_results')">
                                      <p class="catalogue__meta-name">{{ $t('Knockdown') }}</p>
                                      <h5 class="catalogue__meta-value" v-if="article.sold_price">{{ currency }} {{ euNumbers(article.sold_price) }}</h5>
                                      <h5 class="catalogue__meta-value" v-else>-</h5>
                                    </div>
                                  </div>
                                </v-col>
                                <v-row class="ma-0 justify-md-end justify-start" v-if="!isMobile">
                                  <v-col class="pt-0 pt-sm-3 pl-0 flex-grow-0 text-md-right" v-if="selectedAuctionIsSale || selectedAuctionData.type === 'timed'">
                                    <v-btn
                                      @click="buyArticle(article)"
                                      class="btn success mt-2 mr-2"
                                      v-if="selectedAuctionData.status === 'started' && selectedAuctionIsSale && article.buy_price && article.instant_buy_possible"
                                    >
                                      <v-icon small class="mr-2">fa-shopping-cart</v-icon>
                                      <span>{{ $t('Buy now') }}</span>
                                    </v-btn>
                                    <p
                                      class="mt-2 mb-0 text-no-wrap"
                                      v-if="article.status === '2' && !timedError && article.timedData"
                                      readonly
                                    >
                                      <span class="green--text" v-if="article.timedData.bidder_status === 'highest-bidder'">{{ $t('You are the highest bidder') }}</span>
                                      <span class="red--text" v-if="article.timedData.bidder_status === 'outbid'">{{ $t('You are outbid, bid again') }}</span>
                                    </p>
                                    <v-btn
                                      class="btn small old-styling-btn mt-2"
                                      @click="openTimedBidModal(article)"
                                      v-if="article.status === '2' && selectedAuctionData.status === 'started' && !timedError && article.timedData"
                                    >
                                      <span v-if="article.timedData.bidder_status === 'no-bid'">{{ $t('Place bid') }}</span>
                                      <span v-else>{{ $t('Place/Check bid') }}</span>
                                    </v-btn>
                                    <p
                                      class="mt-2 text-no-wrap"
                                      v-else-if="article.status === '0' && !timedError"
                                      readonly
                                    >
                                      <span class="green--text" v-if="article.timedData.bidder_status === 'bought'">{{ $t('You have bought the article') }}</span>
                                      <span class="green--text" v-if="article.timedData.bidder_status === 'highest-bidder'">{{ $t('You have auctioned the article') }}</span>
                                      <span class="red--text" v-if="article.timedData.bidder_status === 'outbid'">{{ $t('Article sold') }}</span>
                                    </p>
                                  </v-col>
                                  <v-row class="px-2" v-if="article.commission_bids && article.commission_bids.length && !isMobile && selectedAuctionData.status !== 'completed' && article.status === '1'" justify="end">
                                    <a class="catalogue__item-change mr-2" @click="openBidModal(article, true)">{{ $t('Change bid') }}</a>
                                  </v-row>
                                  <div
                                    class="bid-form"
                                    v-if="moment(selectedAuctionData.active_until).diff(moment(), 'seconds') > 0 && selectedAuctionData.catalogue_enabled !== 'disabled' && selectedAuctionData.commission_bids_enabled && selectedAuctionData.status !== 'completed' && article.status === '1' && !article.commission_bids.length"
                                  >
                                    <v-row class="px-3">
                                      <v-text-field
                                        type="number"
                                        @input="e => validateFields(article)"
                                        @keydown="e => checkInput(article)"
                                        :rules="[validationHigherThanLimit(article?.limit, 'validation.mustBeHigherThanLimit'), !appFeatures.validateCommissionBids ? validationDividedBy(appSettings.commissionBidMinimumBidStep, appSettings.commissionBidMinimumBidStep, appLocalization.currency) : true]"
                                        v-model="article.bid"
                                        :label="$t('Your absentee bid')"
                                        placeholder=" "
                                        class="input-field static-width mr-2"
                                        :error="validbids.findIndex(el => el.id === article.id) !== -1"
                                        :error-messages="validbids.findIndex(el => el.id === article.id) !== -1 ? validbids.filter(el => el.id === article.id)[0].msg : ''"
                                      >
                                        <template #message="{ message }">
                                          {{ translateValidationIfNeeded(message) }}
                                        </template>
                                      </v-text-field>
                                      <div class="d-flex flex-column">
                                        <v-checkbox
                                          hide-details
                                          class="input-field ma-0"
                                          v-model="article.pickUp"
                                          :label="$t(pickupText(article))"
                                          :disabled="pickupRequired(article)"
                                        />
                                        <v-checkbox
                                          v-if="selectedAuctionData && !selectedAuctionData.no_telephone_bids"
                                          hide-details
                                          class="input-field ma-0"
                                          v-model="article.bid_by_telephone"
                                          :label="`${$t('Bid by telephone')} (${$t('available from')} ${ appLocalization.currency } ${ euNumbers(appSettings.minimum_bid_for_telephone_bidding) })`"
                                          :disabled="checkBidByPhoneDisabled(article)"
                                          :key="article.id + phoneKey"
                                        />
                                      </div>
                                    </v-row>
                                  </div>
                                  <v-tooltip left v-else-if="moment(selectedAuctionData.active_until).diff(moment(), 'seconds') > 0 && selectedAuctionData.catalogue_enabled !== 'disabled' && selectedAuctionData.commission_bids_enabled && article.commission_bids.length">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-on="on">
                                        <v-btn class="btn ml-2" v-bind="attrs" v-on="on" :class="article.commission_bids[0].status"  disabled>
                                          <v-icon v-if="article.commission_bids[0].status === 'pending'" style="font-weight: 400;" class="mr-1">fa-hourglass</v-icon>
                                          <span v-if="article.commission_bids[0].status === 'pending'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                          <v-icon v-if="article.commission_bids[0].status === 'accepted'" class="green-color-success mr-1">fa-check</v-icon>
                                          <span v-if="article.commission_bids[0].status === 'accepted'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                          <v-icon v-if="article.commission_bids[0].status === 'declined'" class="red-color mr-1">fa-times</v-icon>
                                          <span v-if="article.commission_bids[0].status === 'declined'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                        </v-btn>
                                      </div>
                                    </template>
                                    <span v-if="article.commission_bids[0].status === 'pending'">{{ $t('Absentee bid pending') }}</span>
                                    <span v-else-if="article.commission_bids[0].status === 'accepted'">{{ $t('Absentee bid accepted') }}</span>
                                    <span v-else-if="article.commission_bids[0].status === 'declined'">{{ $t('Absentee bid declined') }}</span>
                                  </v-tooltip>
                                </v-row>
                              </v-row>
                              <p class="catalogue__description">{{ articleDescription(article) }}</p>
                              <template v-if="(selectedAuctionIsSale || selectedAuctionData.type === 'timed') && isMobile">
                                <v-btn
                                  @click="buyArticle(article)"
                                  class="btn success small mt-2"
                                  v-if="selectedAuctionData.status === 'started' && selectedAuctionIsSale && article.buy_price && article.instant_buy_possible"
                                >
                                  <v-icon small class="mr-2">fa-shopping-cart</v-icon>
                                  <span>{{ $t('Buy now') }}</span>
                                </v-btn>
                                <p
                                  class="mt-2 mb-0"
                                  v-if="article.status === '2' && !timedError && article.timedData"
                                  readonly
                                >
                                  <span class="green--text" v-if="article.timedData.bidder_status === 'highest-bidder'">{{ $t('You are the highest bidder') }}</span>
                                  <span class="red--text" v-if="article.timedData.bidder_status === 'outbid'">{{ $t('You are outbid, bid again') }}</span>
                                </p>
                                <v-btn
                                  class="btn catalogue__item-shopping old-styling-btn small mt-2"
                                  @click="openTimedBidModal(article)"
                                  v-if="article.status === '2' && selectedAuctionData.status === 'started' && !timedError && article.timedData"
                                >
                                  <span v-if="article.timedData.bidder_status === 'no-bid'">{{ $t('Place bid') }}</span>
                                  <span v-else>{{ $t('Place/Check bid') }}</span>
                                </v-btn>
                                <p
                                  class="mt-2"
                                  v-else-if="article.status === '0' && !timedError"
                                  readonly
                                >
                                  <span class="green--text" v-if="article.timedData.bidder_status === 'bought'">{{ $t('You have bought the article') }}</span>
                                  <span class="green--text" v-if="article.timedData.bidder_status === 'highest-bidder'">{{ $t('You have auctioned the article') }}</span>
                                  <span class="red--text" v-if="article.timedData.bidder_status === 'outbid'">{{ $t('Article sold') }}</span>
                                </p>
                              </template>
                              <template v-if="isMobile">
                                <div
                                  class="bid-form mt-2"
                                  v-if="moment(selectedAuctionData.active_until).diff(moment(), 'seconds') > 0 && selectedAuctionData.catalogue_enabled !== 'disabled' && selectedAuctionData.commission_bids_enabled && selectedAuctionData.status !== 'completed' && article.status === '1' && !article.commission_bids.length"
                                >
                                  <v-row class="px-3">
                                    <v-text-field
                                      type="number"
                                      @input="e => validateFields(article)"
                                      @keydown="e => checkInput(article)"
                                      :rules="[validationHigherThanLimit(article?.limit, 'validation.mustBeHigherThanLimit'), !appFeatures.validateCommissionBids ? validationDividedBy(appSettings.commissionBidMinimumBidStep, appSettings.commissionBidMinimumBidStep, appLocalization.currency) : true]"
                                      v-model="article.bid"
                                      :label="$t('Your absentee bid')"
                                      placeholder=" "
                                      class="input-field mr-2"
                                      :error="validbids.findIndex(el => el.id === article.id) !== -1"
                                      :error-messages="validbids.findIndex(el => el.id === article.id) !== -1 ? validbids.filter(el=>el.id === article.id)[0].msg : ''"
                                    >
                                      <template #message="{ message }">
                                        {{ translateValidationIfNeeded(message) }}
                                      </template>
                                    </v-text-field>
                                    <v-checkbox
                                      hide-details
                                      class="input-field"
                                      v-model="article.pickUp"
                                      :label="$t(pickupText(article))"
                                      :disabled="pickupRequired(article)"
                                    />
                                    <v-checkbox
                                      v-if="selectedAuctionData && !selectedAuctionData.no_telephone_bids"
                                      hide-details
                                      class="input-field ma-0"
                                      v-model="article.bid_by_telephone"
                                      :label="`${$t('Bid by telephone')} (${$t('available from')} ${ appLocalization.currency } ${ euNumbers(appSettings.minimum_bid_for_telephone_bidding) })`"
                                      :disabled="checkBidByPhoneDisabled(article)"
                                      :key="article.id + phoneKey"
                                    />
                                  </v-row>
                                </div>
                                <v-btn class="btn mt-2 small" :class="article.commission_bids[0].status" v-else-if="moment(selectedAuctionData.active_until).diff(moment(), 'seconds') > 0 && selectedAuctionData.catalogue_enabled !== 'disabled' && selectedAuctionData.commission_bids_enabled && article.commission_bids.length" disabled>
                                  <span v-if="article.commission_bids[0].status === 'pending'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                  <span v-if="article.commission_bids[0].status === 'accepted'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                  <span v-if="article.commission_bids[0].status === 'declined'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                </v-btn>
                                <v-row class="pb-3 px-3 justify-start justify-md-end mt-md-0 mt-2" v-if="article.commission_bids && article.commission_bids.length && selectedAuctionData.status !== 'completed' && article.status === '1'">
                                  <a class="catalogue__item-change" @click="openBidModal(article, true)">{{ $t('Change bid') }}</a>
                                </v-row>
                              </template>
                            </div>
                          </div>
                        </div>
                      </v-form>
                    </div>
                  </v-col>
                  <v-col sm="12" class="text-center" v-else>
                    <h3 class="no-data-text">{{ $t('There are no items on your wishlist') }}</h3>
                  </v-col>
                </template>
                <v-alert
                  v-if="!disabledSendBtn && !validbids.length>0"
                  border="left"
                  dense
                  outlined
                  text
                  type="info"
                >
                    {{ $t('absentee_bids_info') }}
                    <br>
                    <span v-if="appSettings.commission && appSettings.commission.absentee !== 0">{{ $t('absentee_bids_info_commission', {tAuctionCommissionRate: this.selectedAuctionData.surcharge, tCommissionBiddingRate: appSettings.commission.absentee}) }}</span>
                    <span v-else>{{ $t('absentee_bids_info_commission_none', {tAuctionCommissionRate: this.selectedAuctionData.surcharge}) }}</span><br/>
                    <span v-if="showTotalCommissionCosts">{{totalCostsText}}</span>
                </v-alert>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
      <div class="pagination-container" v-if="auctions && totalItems">
        <v-row class="ma-0" justify="center" align="center">
          <v-col class="pa-0">
            <v-row class="ma-0" align="center">
              <v-menu offset-y close-on-content-click>
                <template #activator="{ on, attrs }">
                  <v-btn
                    text
                    v-bind="attrs"
                    v-on="on"
                    class="dropdown-btn customizable"
                  >
                    <span class="highlight">{{ countPerPageDefault }}<v-icon class="ml-1">fa-angle-down</v-icon></span>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(item, index) in itemsPerPage"
                    :key="index"
                    @click="changePerPage(item)"
                  >
                    <v-list-item-title class="text-right">{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <div class="ml-2 mr-2" v-if="!isMobile">
                {{ page * countPerPageDefault + 1 }}-{{ totalPages > 1 ? totalPages === page + 1 ? totalItems : (page + 1) * countPerPageDefault : totalItems }} {{ $t('of') }} {{ totalItems }} {{ $t('items') }}
              </div>
            </v-row>
          </v-col>
          <custom-pagination v-if="totalPages > 1" :page="page + 1" @changePage="changePage" :length="totalPages" :totalVisible="10" />
        </v-row>
      </div>
      <CoolLightBox
        :items="selectedImages"
        :index="lightBoxIndex"
        :useZoomBar="true"
        @close="lightBoxIndex = null"
      />
    </div>
    <v-col class="text-center" v-if="getAuctionError">
      <div class="status__item status__item--error">
        <div class="status__icon" />
        <p class="status__text">{{ $t(`This auction doesn't exist`) }}</p>
      </div>
    </v-col>
    <user-article-inquiry-modal
      ref="inquiryModal"
      :article="selectedArticle"
      @clearSelectedArticle="selectedArticle = {}"
    />
    <change-bid-modal
      :commissionBidDialog="bidDialog"
      :editBid="editBid"
      :loading="loading"
      :selectedArticle="selectedArticle"
      :activePage="page"
      :selectedAuctionData="selectedAuctionData"
      update-data
      @getAuctionDetail="getAuctionDetail"
      @setLoading="e => loading = e"
      @closeBidModal="closeBidModal"
    />
    <timed-bid-modal
      :auction="selectedAuctionData"
      :article="selectedArticle"
      ref="timedBidModal"
    />
    <instant-buy-modal
      ref="instantBuyModal"
      :loading="instantBuyLoading"
      @submitBuyArticle="submitBuyArticle"
      @clearSelectedArticle="selectedArticle = {}"
    />
  </div>
</template>
<script>
import _ from 'lodash'
import { mapActions, mapState } from 'pinia'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import SearchBox from '@/views/settings/articles/sections/search'
import ChangeBidModal from '@/components/modals/user-commission-bid-modal'
import TimedBidModal from '@/components/modals/user-timed-bid-modal'
import UserArticleInquiryModal from '@/components/modals/user-article-inquiry-modal'
import InstantBuyModal from '@/components/modals/user-request-instant-buy'
import { AuctionFlavor, localFeatures, timeouts } from '@/utils/constants'
import counter from '@/mixins/counter'
import CustomPagination from '@/components/blocks/custom-pagination.vue'
import dispatcher from '@/api/dispatch';
import frontendValidations from '@/utils/rules';
import moment from 'moment';
import useRootStore from '@/stores/rootStore';
import GlobalEvents, { GlobalEventType } from '@/utils/globalEvents';
import { createArticleSearchIndex, searchArticles } from '@/services/article';
import {
  euNumbers,
  getFormattedDate,
  getTranslatedText,
  translateValidationIfNeeded
} from '@/services/i18n';

export default {
  name: 'WishListModal',
  components: {
    VueSlickCarousel,
    CoolLightBox,
    SearchBox,
    ChangeBidModal,
    TimedBidModal,
    UserArticleInquiryModal,
    InstantBuyModal,
    CustomPagination
  },
  data () {
    return {
      renderComponent: true,
      instantBuyLoading: false,
      getAuctionError: false,
      search: '',
      countPerPageDefault: localFeatures.elementsPerPageDefault,
      itemsPerPage: localFeatures.elementsPerPage,
      page: 0,
      start: 0,
      end: 0,
      key: 0,
      error: '',
      loading: false,
      articlesView: [],
      tmpArticles: [],
      wishListArticles: [],
      selectedImages: [],
      lightBoxIndex: null,
      auctions: null,
      selectedAuctionData: null,
      wishList: null,
      currentAuction: null,
      auctionLoading: false,
      selectedArticle: {},
      bidDialog: false,
      editBid: false,
      disableButton: true,
      disabledSendBtn: true,
      bid: null,
      phoneKey: 0,
      timedError: false,
      bidsData: null,
      localySelected: true,
      showImages: {},
      validbids: [],
      validbid: { error: false, msg: '' },
      searchKeys: ['name', 'description'], // number and number_optional will always be searched
      articleSearchIndex: null,
      showTotalCommissionCosts: localFeatures.showTotalCommissionCosts
    }
  },
  mixins: [
    counter
  ],
  computed: {
    ...mapState(useRootStore, ['globalTheme', 'userProfile', 'appLocalization', 'appSettings', 'appStoreLang', 'auctionsFetchedArticles', 'isMobile', 'appFeatures', 'preselectionData']),
    totalItems() {
      return this.tmpArticles.length
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.countPerPageDefault)
    },
    emptyFields() {
      return false
    },
    disabledSave() {
      return true
    },
    headers() {
      return [
        {
          text: this.$t('Article #'),
          value: 'number',
        },
        {
          text: this.$t('Article name'),
          value: 'name',
        },
        {
          text: this.$t('Description'),
          value: 'description',
        }
      ]
    },
    currency () {
      return this.appLocalization.currency
    },
    selectedAuctionDate() {
      const d = new Date(this.selectedAuctionData.start_at);
      const ye = d.getFullYear()
      const mo = d.toLocaleString(this.appStoreLang, { month: 'long', timeZone: 'UTC' });
      const da = d.getDate()
      const h = d.getHours()
      const m = d.getMinutes()
      const dayPrefix = da === 1 ? 'st' : da === 2 ? 'nd' : da === 3 ? 'rd' : 'th'
      return this.appStoreLang === 'en' ? `${mo} ${da}${dayPrefix}, ${ye} ${h}:${m <= 9 ? '0' + m : m}` : `${da}. ${mo}, ${ye} ${h}:${m <= 9 ? '0' + m : m}`
    },
    selectedAuctionIsSale() {
      return this.selectedAuctionData.type === 'sale'
    },
    preselectedAuction() {
      return this.$route.params.id
    },
    totalCostsText() {
      const costs = ((this.selectedAuctionData?.surcharge + this.appSettings?.commission.absentee) * ((this.selectedAuctionData?.tax / 100) +1)).toFixed(2)
      return this.$t('absentee_bids_info_total_costs', {tCommissionBiddingTotalCosts: costs})
    }
  },
  async created() {
    await this.getAuctions()
    if (this.preselectedAuction) {
      this.localySelected = false
      this.currentAuction = this.auctions.find(el => el.code.toString() === this.preselectedAuction.toString())
      if (this.currentAuction) {
        this.selectAuction(this.currentAuction.id)
      } else {
        this.getAuctionError = true
      }
    }
    this.articleSearchIndex = createArticleSearchIndex(this.tmpArticles); // is always empty because article data is loaded afterwards, watcher needed to update index
  },
  async mounted() {
    // every time the view is shown i.e. on a route change
    // if no auction is preselected via the url and there is one preselected in the store, use this
    if (this.preselectionData.selectedAuctionId) {
      this.auctionLoading = true
      this.search = null
      await this.getAuctions()
      const targetAuction = this.auctions.find(el => el.id === this.preselectionData.selectedAuctionId);
      this.selectedAuctionData = targetAuction
      this.currentAuction = targetAuction
      if (this.selectedAuctionData && this.selectedAuctionData.code !== this.$route.params.id) this.$router.push({ params: { id: this.selectedAuctionData.code } })
      await this.getAuctionDetail(this.selectedAuctionData.id)
    }
    GlobalEvents.subscribeEvent(GlobalEventType.updateArticle, this.handleAuctionDataUpdate)
  },
  async unmounted() {
    GlobalEvents.unsubscribeEvent(GlobalEventType.updateArticle, this.handleAuctionDataUpdate)
  },
  methods: {
    translateValidationIfNeeded,
    ...mapActions(useRootStore, ['updateUserProfile', 'getArticles', 'SET_TEMP_ALERT']),
    validationRequired: frontendValidations.required,
    validationHigherThanLimit: frontendValidations.higherThanLimit,
    validationDividedBy: frontendValidations.dividedBy,
    getFormattedDate,
    async removeFromWishList(article) {
      try {
        await dispatcher.removeFromWishlist(this.selectedAuctionData.id, this.userProfile.id, article.id);
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Article has been removed from your wishlist'), timeout: 1000 })
        const target = this.wishListArticles.findIndex(el => +el.id === +article.id)
        const targetOnCurrentPage = this.articlesView.findIndex(el => +el.id === +article.id)
        this.wishListArticles.splice(target, 1)
        this.articlesView.splice(targetOnCurrentPage, 1)
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error removing that article from the wishlist. Please try again later.') })
      }
    },
    getFieldName(obj, field) {
      let langTarget
      if (typeof obj[`${field}_i18n`] === 'string' && obj[`${field}_i18n`].length > 0) {
        langTarget = JSON.parse(obj[`${field}_i18n`])
      } else {
        langTarget = obj[`${field}_i18n`]
      }
      if (langTarget && Object.keys(langTarget).length > 0 && langTarget[this.appStoreLang]) {
        return `${field}_i18n`
      }
      return field
    },
    euNumbers,
    changePage (el) {
      this.page = el - 1
      this.$refs.anchor.scrollIntoView({ behavior: 'smooth' })
      this.getArticlesData()
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },

    async getAuctionDetail(id, getTimedData = true) {
      try {
        const targetAuction = this.auctions.find(el => el.id === id)
        await this.getArticles({ auctionID: id })
        try {
          const wishListResp = await dispatcher.getWishlist(id, this.userProfile.id)
          this.wishList = wishListResp
        } catch (e) {
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('The wishlist is currently unavailable due to a technical error. Please try again later.') })
          throw e;
        }
        const items = []
        let bidsResp
        if (targetAuction.type === AuctionFlavor.live) {
          try {
            bidsResp = await dispatcher.getAllCommissionBids(id, false)
            this.bidsData = bidsResp
          } catch (e) {
            this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('Absentee bid planning is currently unavailable due to an technical error. Please try again later.') })
          }
        }
        this.wishList.forEach(item => {
          this.auctionsFetchedArticles[`auction_${id}`].forEach(el => {
            if (el.id === item.articles_id) {
              items.push({
                ...el,
                wishlist: true,
                wishlistId: item.id,
                commission_bids: bidsResp && bidsResp.data ? bidsResp.data.commission_bids.filter(bid => bid.articles_id === el.id) : [],
                bid: null,
                pickUp: false
              })
            }
          })
        })

        const sorted = [...items.sort((a, b) => {
          if (a.number && b.number) {
            return a.number - b.number
          } else {
            return a.number_optional - b.number_optional
          }
        })]
        this.wishListArticles = sorted
        this.tmpArticles = sorted
        this.error = null
        if (getTimedData && this.selectedAuctionData.type === 'timed') await this.getTimedData(id)
        this.getArticlesData()
        this.auctionLoading = false
      } catch (e) {
        this.auctionLoading = false
        this.loading = false
      }
    },

    async getTimedData(id) {
      try {
        const result = await dispatcher.getArticleTimedStatus(id, "all")
        if (result.timedBids && result.timedBids.length) {
          result.timedBids.forEach(el => {
            this.wishListArticles.forEach(article => {
              if (el.article_id === article.id) {
                article.timedBidData = el
              }
            })
            this.tmpArticles.forEach(article => {
              if (el.article_id === article.id) {
                article.timedBidData = el
              }
            })
          })
        }
        this.timedError = false
      } catch (e) {
        this.SET_TEMP_ALERT({
          flavor: 'error',
          content: this.$t('There was an error getting timed data. Please try again later')
        })
        this.timedError = true
      }
    },

    getArticlesData() {
      this.articlesView = this.tmpArticles.slice(this.page * this.countPerPageDefault, (this.page + 1) * this.countPerPageDefault)
    },
    searchArticles,
    searchHandler: _.debounce(function () {
      this.page = 0
      if (this.search) {
        this.tmpArticles = this.searchArticles(this.articleSearchIndex, this.search, this.wishListArticles, this.searchKeys, this.appStoreLang)
      } else {
        this.tmpArticles = [...this.wishListArticles]
      }
      this.getArticlesData()
    }, 500),
    articleTitle (article) {
      return getTranslatedText(article, 'name', this.appStoreLang)
    },
    articleDescription (article) {
      return getTranslatedText(article, 'description', this.appStoreLang)
    },
    auctionName (auction) {
      return getTranslatedText(auction, 'name', this.appStoreLang)
    },
    changePerPage(item) {
      this.countPerPageDefault = item
      this.page = 0
      this.getArticlesData()
    },

    close () {
      this.$router.push({ name: 'dashboard' })
    },
    selectImage(e, images, index) {
      if (e.target.classList.contains('image-item')) {
        e.target.blur()
      } else {
        e.target.parentNode.blur()
      }
      this.selectedImages = images.map(({ image }) => image)
      this.lightBoxIndex = index
    },

    /**
     * Get all auctions from api and filter according to conditionals
     * @return {any} - Modified auctions
     */
    async getAuctions() {
      try {
        const data = await dispatcher.getAllAuctions();
        this.auctions = data.filter(el => moment(el.active_until).diff(moment(), 'seconds') > 0 && el.catalogue_enabled !== 'disabled')
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting the auctions. Please try again later') });
        this.error = true;
      }
    },

    selectAuction(id) {
      this.auctionLoading = true
      this.selectedAuctionData = this.auctions.find(el => el.id.toString() === id.toString())
      this.getAuctionDetail(id)
      if (this.selectedAuctionData && this.selectedAuctionData.code !== this.$route.params.id) this.$router.push({ params: { id: this.selectedAuctionData.code } })
    },

    async sendBids() {
      try {
        const commissionBids = []
        this.articlesView.forEach(el => {
          if (el.bid) {
            commissionBids.push({
              auction_id: this.selectedAuctionData.id,
              articles_id: el.id,
              commission_bid: +el.bid,
              pickup: (this.selectedAuctionData && this.selectedAuctionData.no_shipping) ? true : el.pickUp,
              bid_by_telephone: el.bid_by_telephone
            })
          }
        })

        this.loading = true
        await dispatcher.placeCommissionBids(this.selectedAuctionData.id, { commission_bids: commissionBids })
        await this.getAuctionDetail(this.selectedAuctionData.id)
        this.loading = false
        this.SET_TEMP_ALERT({
          flavor: 'success',
          content: this.$t('We have received your absentee bids and will process it as soon as possible')
        })
        this.disabledSendBtn = true
      } catch (e) {
        if (e.data?.error && e.data.data[0].errorShortText === 'user.commission.bid.exists') {
          this.SET_TEMP_ALERT({
            flavor: 'error',
            content: this.$t('You already placed this absentee bid')
          })
        } else if (e.data?.error && e.data.data[0].errorShortText === 'user.profile.incomplete') {
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: this.$t('An error has occured'),
            text: this.$t('Your profile is incomplete and is missing required information for this functionality. Please complete your profile and try again'),
            showConfirmButton: false,
            timer: timeouts.closeSweetalert
          })
        } else {
          this.SET_TEMP_ALERT({
            flavor: 'error',
            content: this.$t('A technical error occured while trying to submit absentee bids. Please try again later.')
          })
        }
        this.disableButton = false
        this.loading = false
      }
    },
    validateFields: _.debounce(function (article) {
      if (this.articlesView.find(el => el.bid)) this.disabledSendBtn = false
      if (this.appSettings.minimum_bid_for_telephone_bidding > article.bid) {
        article.bid_by_telephone = false
      }
      if (!this.appFeatures.validateCommissionBids && (!article.bid || (+article.bid < +article.limit) || +article.bid % this.appSettings.commissionBidMinimumBidStep !== 0)) {
        this.phoneKey++
        this.disabledSendBtn = true
      } else if (this.appFeatures.validateCommissionBids && (!article.bid || (+article.bid < +article.limit))) {
        this.phoneKey++
        this.disabledSendBtn = true
      }

      if (!this.disabledSendBtn && this.appFeatures.validateCommissionBids) {
        this.validateBids(article); // eslint-disable-line
      }
    }, timeouts.debounce),
    checkBidByPhoneDisabled(article) {
      return article.bid < article.limit || +article.bid % this.appSettings.commissionBidMinimumBidStep !== 0 || this.appSettings.minimum_bid_for_telephone_bidding > article.bid;
    },
    checkInput(article) {
      let index = this.validbids.findIndex(el => el.id === article.id);
      if (index !== -1) {
        this.validbids.splice(index, 1);
      }
    },
    async validateBids(article) {
      try {
        if (article && article.bid) {
          const data = {
            commission_bids: [{
              auction_id: this.selectedAuctionData.id,
              articles_id: article.id,
              commission_bid: +article.bid,
              pickup: article.pickUp,
              bid_by_telephone: article.bid_by_telephone
            }]
          }
          const res = await dispatcher.validateCommissionBids(this.selectedAuctionData.id, data)
          if (res && !res[0].valid) {
            let index = this.validbids.findIndex(el => el.id === article.id);
            if (index > -1) {
              this.validbids[index] = { id: article.id, msg: this.$t('Invalid commission bid, the current bid step is') + ' ' + res[0].bid_step + '. ' + this.$t('The next valid bid would be') + ' ' + res[0].next_valid_commission_bid };
            } else {
              this.validbids.push({ id: article.id, msg: this.$t('Invalid commission bid, the current bid step is') + ' ' + res[0].bid_step + '. ' + this.$t('The next valid bid would be') + ' ' + res[0].next_valid_commission_bid });
            }
            this.validbid.error = true;
            this.validbid.msg = this.$t('Invalid commission bid, the current bid step is') + ' ' + res[0].bid_step + '. ' + this.$t('The next valid bid would be') + ' ' + res[0].next_valid_commission_bid;
          }
        }
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error validating the commission bids. Please try again later') })
      }
    },
    openBidModal(article, editMode = false) {
      this.selectedArticle = article
      this.bidDialog = true
      this.editBid = editMode
      this.validbid.error = false;
      this.validbid.msg = ''
    },
    closeBidModal() {
      this.bidDialog = false
      this.selectedArticle = {}
    },
    openTimedBidModal(article) {
      this.selectedArticle = article
      this.$refs.timedBidModal.openModal()
    },
    openInquiryModal(article) {
      this.selectedArticle = article
      this.$refs.inquiryModal.open()
    },

    async submitBuyArticle() {
      try {
        this.instantBuyLoading = true
        const data = {
          type: 'instant-buy',
          lang: this.appStoreLang,
          article_id: this.selectedArticle.id
        }
        await dispatcher.sendEmailInquiry(this.userProfile.id, data)
        this.SET_TEMP_ALERT({
          flavor: 'success',
          content: this.$t('We have received your request and will get back to you as soon as possible')
        })
        this.$refs.instantBuyModal.closeModal()
        this.instantBuyLoading = false
        await this.getAuctionDetail(this.currentAuction.id)
      } catch (e) {
        this.SET_TEMP_ALERT({
          flavor: 'error',
          content: this.$t('There was an error sending the inquiry. Please try again later')
        })
        this.instantBuyLoading = false
      }
    },
    buyArticle(article) {
      this.selectedArticle = article
      if (this.selectedAuctionIsSale) {
        this.$refs.instantBuyModal.openModal(`${this.currency} ${euNumbers(article.buy_price)}`)
      }
    },
    showImageToggle(id) {
      this.showImages = {
        ...this.showImages,
        [`article${id}`]: true
      }
    },
    pickupText() {
      if (this.selectedAuctionData && this.selectedAuctionData.no_shipping) {
        return 'Pick up this lot if bought (shipping is impossible for that auction)'
      } else if (this.selectedArticle && this.selectedArticle.no_shipping) {
        return 'Pick up this lot if bought (shipping is impossible for that article)';
      } else {
        return 'Pick up this lot if bought';
      }
    },
    pickupRequired() {
      return (this.selectedArticle && this.selectedArticle.no_shipping) || (this.selectedAuctionData && this.selectedAuctionData.no_shipping)
    },
    handleAuctionDataUpdate() {
      {
        const rootStore = useRootStore();
        if (!this.selectedAuctionData || !this.tmpArticles || !this.wishList) return false
        let articles = rootStore.auctionsFetchedArticles[`auction_${this.selectedAuctionData.id}`]
        if (!articles) return

        const updatedArticles = articles.map(el => {
          const prevVal = this.tmpArticles.find(article => article.id === el.id)
          return {
            ...prevVal,
            ...el
          }
        })

        const items = []

        this.wishList.forEach(item => {
          updatedArticles.forEach(el => {
            if (el.id === item.articles_id) {
              items.push({
                ...el,
                wishlist: true,
                wishlistId: item.id,
                commission_bids: this.bidsData ? this.bidsData.commission_bids.filter(bid => bid.articles_id === el.id) : [],
                bid: null,
                pickUp: (this.selectedAuctionData.type === 'live' && this.pickupRequired(el))
              })
            }
          })
        })

        const sorted = [...items.sort((a, b) => {
          if (a.number && b.number) {
            return a.number - b.number
          } else {
            return a.number_optional - b.number_optional
          }
        })]

        this.tmpArticles = sorted

        this.key++
        this.getArticlesData()
      }
    }
  },
  watch: {
    wishListArticles: {
      deep: true,
      handler(val) {
        this.articleSearchIndex = createArticleSearchIndex(val);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.catalogue {
  .v-dialog {
    .dropdown-btn {
      color: #000 !important;
    }
  }
  &__item-buy {
    background-color: green !important;
    &.small {
      display: block;
      cursor: pointer;
      width: 100%;
      max-width: 240px;
      font-size: 10px;
      padding: 0 6px !important;
      height: 28px !important;
      i {
        font-size: 14px;
      }
    }
  }
  .static-width {
    width: 180px;
  }
}
</style>
