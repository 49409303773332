<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <div class="bid-transactions-modal">
      <div class="bid-transactions-container">
        <div class="close-btn-wrapper">
          <v-btn class="close-btn mb-2" @click="$router.go(-1)">
            <v-icon class="close-modal-icon">fa-times</v-icon>
          </v-btn>
          <v-btn class="close-btn sync icon-with-hover" @click="syncData" v-if="!_.isEmpty(currentAuction)">
            <v-icon class="close-modal-icon">fa-sync</v-icon>
          </v-btn>
        </div>
        <v-col sm="12" class="full-width">
          <v-row class="search-wrap">
            <v-col align="center">
              <div class="page-title my-3">{{ $t('Statistics') }}</div>
            </v-col>
          </v-row>
        </v-col>
        <v-card class="main-card" flat>
          <v-container>
            <div>
              <v-row align="center" class="form-container justify-center">
                <v-col sm="12" class="full-width">
                  <v-col class="main-col-wrapper">
                    <v-col align="center" class="sub-col-wrapper">
                      <v-row>
                        <search-box
                          v-if="auctions && auctions.length > 1"
                          :currentAuction="currentAuction"
                          :auctions="auctions"
                          hide-create-new
                          @onSelectAuction="getAuctionDetail"
                          key="auction-search"
                        />
                      </v-row>
                    </v-col>
                  </v-col>
                </v-col>
                <v-progress-circular v-if="loading" class="loading-icon mt-6" indeterminate />
                <template v-else-if="!error">
                  <v-col sm="12" v-if="!_.isEmpty(currentAuction) && chartData && auctions && auctions.length > 1" class="pb-16">
                    <v-row>
                      <v-col
                        v-for="(item, i) in chartData"
                        :key="i"
                        class="chart-col"
                      >
                        <Chart
                          :chartdata="item.chartData"
                          :options="item.options"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <div class="full-width mt-6" v-else-if="auctions && auctions.length === 1">
                    <div class="info-text" style="max-width: 400px;">
                      <v-icon>fa-circle-info</v-icon>
                      <p>{{ $t('No auctions found') }}</p>
                    </div>
                  </div>
                </template>
                <v-col sm="12" class="text-center" v-else>
                  <h3 class="no-data-text">{{ $t(error) }}</h3>
                </v-col>
              </v-row>
            </div>
          </v-container>
          <Footer />
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import Footer from '@/components/footer'
import SearchBox from '@/views/settings/articles/sections/search'
import Chart from '@/views/statistics/chart'
import { colors } from '@/utils/constants'
import dispatcher from '@/api/dispatch';
import _ from 'lodash';

export default {
  name: 'BidTransactions',
  computed: {
    _() {
      return _
    }
  },
  components: {
    Footer,
    SearchBox,
    Chart
  },
  data: () => ({
    renderComponent: true,
    currentAuction: {
      id: 'all'
    },
    key: 0,
    error: '',
    chartData: null,
    loading: false,
    auctions: null,
  }),
  async created() {
    await this.getAuctions()
    await this.getAuctionDetail(this.currentAuction.id)
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },

    async getAuctionDetail(auctionID) {
      this.loading = true
      this.page = 0
      try {
        const result = await dispatcher.getStatistics(auctionID)

        this.error = null
        this.currentAuction.id = auctionID
        this.chartData = this.setChartData(result)
        this.key++
      } catch (e) {
        this.currentAuction.id = {}
        this.articles = []
        this.error = this.$t('No bid transactions found')
      }
      this.loading = false
    },

    setChartData(chartData) {
      const dataset = []
      for (let key of Object.keys(chartData)) {
        const keyItem = chartData[key]
        const chartTitle = `${this.$t(key)} (${keyItem.total})`
        delete (keyItem.total)
        const labels = []
        const dataSet = []
        for (let nameKey of Object.keys(keyItem)) {
          labels.push(this.$t(`${key}_${nameKey}`) + ` (${keyItem[nameKey] || 0})`)
          dataSet.push(keyItem[nameKey])
        }
        const data = {
          chartData: {
            series: dataSet
          },
          options: {
            chart: {
              type: 'donut'
            },
            labels,
            colors: colors[key],
            legend: {
              position: 'top'
            },
            title: {
              display: true,
              text: chartTitle,
              align: 'center'
            },
          }
        }
        dataset.push(data)
      }
      return dataset
    },

    async getAuctions() {
      try {
        const data = await dispatcher.getAllAuctions();
        this.auctions = data
        this.auctions.unshift({
          code: this.$t('All'),
          id: 'all'
        })
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting the auctions. Please try again later') });
        this.error = true;
      }
    },

    async syncData() {
      await this.getAuctionDetail(this.currentAuction.id)
      this.getArticleDetail(this.articleId)
    }
  }
}
</script>
<style lang="scss" scoped>
.bid-transactions-container {
  .close-btn-wrapper {
    position: fixed;
    top: 110px;
    right: 40px;
    z-index: 1;

    .v-ripple__container {
      display: none !important;
    }

    @media(max-width: 968px) {
      top: 90px;
      right: 10px;
    }

    .close-text {
      @media(max-width: 968px) {
        display: none;
      }
    }

    .close-modal-icon {
      font-size: 24px;
      width: 24px;
      height: 24px;
      background: transparent;
      border-radius: 8px;
      border: 1px solid transparent;
      transition: all .3s ease;
    }

    button {
      position: static !important;
      text-transform: none;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      background-color: transparent !important;
      box-shadow: none;
      letter-spacing: unset !important;
      @media(max-width: 968px) {
        width: 40px;
        min-width: 40px !important;
        height: 40px;
        padding: 0 !important;
      }

      &:before, &:focus:before, &:hover:before {
        background-color: transparent !important;
      }

      i {
        @media(max-width: 968px) {
          font-size: 20px !important;
          margin-left: 0 !important;
        }
      }
    }
  }
}
.page-title {
  font-size: 24px;
  line-height: 25px;
  font-weight: 400;
}
.knockdown-table {
  width: 1170px;
  max-width: 100%;
  margin: 0 auto;
}
.article-name {
  font-size: 12px;
  -webkit-line-clamp: 1;
  @media (max-width: 968px) {
    white-space: pre-wrap;
    -webkit-line-clamp: unset;
  }
}
.no-data-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #000000;
}
.chart-col {
  min-width: 400px;
  max-width: 400px !important;
}
</style>
