<template>
  <v-dialog
    width="600"
    v-model="validate2FADialogOpen"
    @keydown.esc="closeModal"
    persistent
  >
    <v-card class="pa-5">
      <v-btn class="modal-close-btn icon-with-hover" icon @click="closeModal"><v-icon>fa-times</v-icon></v-btn>
      <v-card-title class="justify-center">
        <span class="headline">{{ $t('Additional verification required') }}</span>
      </v-card-title>
      <v-card-text>
        <span v-if="text">{{ $t(text) }}</span>
        <v-form ref="tfaForm" v-model="isFormValid" @submit.prevent>
          <v-text-field
            type="number"
            v-model="tfaValue"
            :rules="[validationRequired(), validationPositiveNumber()]"
            :label="$t('Verification code')"
            placeholder=" "
            persistent-hint
            class="input-field mt-2"
            single-line
            @keyup.enter="isFormValid ? processCode() : {};"
          >
            <template #message="{ message }">
              {{ translateValidationIfNeeded(message) }}
            </template>
          </v-text-field>
          <v-alert
            v-if="tfaValidationFailed"
            class="show-here"
            border="left"
            dense
            text
            type="error"
          >
            {{ $t('There was an error validating the code') }}
          </v-alert>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center mt-2">
          <v-btn
            class="btn primary-btn success full-width"
            :disabled="!isFormValid"
            @click="processCode"
            :loading="loading"
          >
            {{ $t('Proceed') }}
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import frontendValidations from '@/utils/rules';
import { mapActions, mapState } from 'pinia';
import useRootStore from '@/stores/rootStore';
import { translateValidationIfNeeded } from '@/services/i18n';
import dispatcher from '@/api/dispatch';

export default {
  name: 'validateTfaDialog',
  props: {
    email: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    }
  },
  emits: ['tfaValidationSucceeded'],
  data() {
    return {
      validate2FADialogOpen: false,
      isFormValid: false,
      tfaValue: null,
      loading: false,
      tfaValidationFailed: false
    }
  },
  methods: {
    ...mapActions(useRootStore, ['SET_TEMP_ALERT', 'UPDATE_GLOBAL_STATE']), //TODO remove as it is not called in the xml part but in the script part only
    validationRequired: frontendValidations.required,
    validationPositiveNumber: frontendValidations.positiveNumber,
    translateValidationIfNeeded,
    openModal() {
      this.validate2FADialogOpen = true
    },
    closeModal() {
      this.validate2FADialogOpen = false
      this.tfaValidationFailed = false
      this.$refs.tfaForm.reset()
      this.UPDATE_GLOBAL_STATE({
        key: 'showTfaDialog',
        value: false,
      })
    },
    async processCode() {
      this.loading = true;
      try {
        const result = await dispatcher.validateTfa(this.email, this.tfaValue);
        this.$emit('tfaValidationSucceeded', result)
      } catch (e) {
        this.tfaValidationFailed = true
        //this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error validating the code') });
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['showTfaDialog'])
  },
  watch: {
    showTfaDialog(val) {
      this.validate2FADialogOpen = val;
    }
  }
}
</script>
