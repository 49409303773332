<template>
  <div class="auction-container settings auctions">
    <div class="close-btn-wrapper">
      <v-btn class="close-btn" @click="closeModal()">
        <v-icon class="close-modal-icon">fa-times</v-icon>
      </v-btn>
      <v-btn class="close-btn sync mt-2 icon-with-hover" @click="syncData" :disabled="articlesLoading">
        <v-icon class="close-modal-icon">fa-sync</v-icon>
      </v-btn>
    </div>
    <div>
      <v-container>
        <div>
          <v-row align="center" class="search-wrap justify-center">
            <v-col sm="12" class="full-width pb-12">
              <article-dashboard
                :disabled="!articles"
                :articles="articles ? mergedArticles : []"
                :auctionId="currentAuctionId ? +currentAuctionId : 0"
                :loading="articlesLoading"
                :error="fetchingError"
                :auctions="auctions"
                @openUpsertArticleModal="openUpsertArticleModal"
                @deleteArticle="handleDeleteArticle"
                @onImportArticles="handleImportArticle"
                @reFetchArticles="reFetchArticles"
                v-if="auctions && auctions.length && !loading"
              />

              <div v-if="listArtcilesNoImages.length > 0 || validateImageError" class="center start-auction-no-images" @click="dialog = true">
                <v-icon>cancel</v-icon>
                {{ $t('Please click here to show the missing images') }}
              </div>

              <upsert-article-modal
                ref="upsertArticleModal"
                :categories="adminCategories"
                :current-auction="auctions.find(el => +el.id === +currentAuctionId)"
                v-if="auctions && auctions.length"
                @reFetchArticles="reFetchArticles"
              />

              <dialog-confirm
                ref="dialog"
                @onOk="handleOk()"
                :cancelLabel="cancelLabel"
                :key="cancelLabel"
              />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <div v-if="!validateImageError">
          <v-card-title class="headline grey">
            {{ $t('List of articles with no images') }}
          </v-card-title>

          <v-card-text>
            <div class="overview-checkimages-container" v-if="listArtcilesNoImages.length">
              <ul>
                <li v-for="(art,idx) in listArtcilesNoImages" :key="idx">
                  <span>{{ $t('Article #:') }}</span>  {{ getArticleNumber(art) }} - <span class="article-name">({{ art.articleName }})</span>
                </li>
              </ul>
            </div>
          </v-card-text>
        </div>
        <div v-if="validateImageError">
          <v-card-text>
            <div class="overview-checkimages-container">
              {{ validateImageErrorMsg }}
            </div>
          </v-card-text>
        </div>
        <v-card-actions>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import ArticleDashboard from './sections/article-dashboard'
import UpsertArticleModal from '@/components/modals/admin-create-article-modal'
import DialogConfirm from '@/components/dialog/dialog-confirm'
import updateData from '@/mixins/updateData'
import Vue from 'vue'
import dispatcher from '@/api/dispatch';
import moment from 'moment';
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';
import {i18n} from "@/main";

export default {
  name: 'AuctionsSetting',
  components: {
    ArticleDashboard,
    UpsertArticleModal,
    DialogConfirm,
  },
  data: function() {
    return {
      auctions: null,
      loading: false,
      dialogType: null,
      dialogMessage: null,
      dialogHeader: null,
      currentDeleteArticle: null,
      listArtcilesNoImages: [],
      validateImageError: false,
      dialog: false,
      cancelLabel: this.$t('Cancel'),
      isAuctionStarted: false,
      articleErrors: [],
      currentAuctionId: null,
      validationError: true,
      knockdowns: [],
      articlesLoading: false,
      fetchingError: false
    }
  },
  mixins: [
    updateData
  ],
  async mounted () {
    this.articlesLoading = true
    await this.getAuctions()
    await this.getCategories()
    if (this.$route.params.id) {
      this.currentAuctionId = +this.$route.params.id
      this.knockdowns = await this.getAdminKnockdownsPerAuction(this.$route.params.id)
    }
    if (this.$route.query && this.$route.query.articleNumber) {
      const article = this.articles.find(article => article.number.toString() === this.$route.query.articleNumber.toString())
      if (article) {
        this.openUpsertArticleModal(article)
      } else {
        rootStore.SET_TEMP_ALERT({ flavor: 'error', content: this.$t("Article doesn't exist") })
        this.$router.replace({ query: null })
      }
    }
    this.articlesLoading = false
  },
  watch: {
    auctionsFetchedArticles: {
      deep: true,
      handler(val) {
        let articles = val[`auction_${this.currentAuctionId}`]
        if (!articles) return

        this.articles = articles.map(el => {
          const prevVal = this.articles.find(article => article.id === el.id)
          return {
            ...prevVal,
            ...el
          }
        })
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['appLocalization', 'showAuctionModal', 'adminDataChange', 'auctionsFetchedArticles', 'adminCategories']),
    selectedAuctionData() {
      return this.auctions && this.currentAuction && this.auctions.find(el => el.id === this.currentAuction.id)
    },
    mergedArticles() {
      if (typeof this.knockdowns === 'string') return this.articles
      const articles = this.articles.map(article => {
        let data = {
          ...article
        }
        const target = this.knockdowns.find(el => el.articles_id && article.id === el.articles_id.id)
        if (target) {
          data = {
            ...data,
            knockdown: target.articles_id.sold_price,
            bidder_num: target.auction_users_id?.bidder_number,
            type: target.articles_id.live_bidder
          }
        }
        return data
      })
      return articles
    }
  },
  methods: {
    ...mapActions(useAuctionStore, ['getAdminKnockdownsPerAuction']),
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'fetchAdminCategories', 'getAdminArticles', 'SET_TEMP_ALERT', 'SET_ALERT']),
    getArticleNumber(itm) {
      if (itm.number_optional.length > 0) {
        return `${itm.number_optional}(${itm.article})`
      } else {
        return itm.article
      }
    },
    async getAuctions() {
      try {
        this.loading = true
        this.isAuctionStarted = false
        const data = await dispatcher.getAllAuctions();
        this.auctions = data
        this.isAuctionStarted = data.some((itm) => (itm.status === 'paused' || itm.status === 'started'))
        await this.getAuctionDetail(this.$route.params.id);
        this.loading = false;
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting the auctions. Please try again later') });
        this.error = true;
        this.loading = false;
      }
    },

    async getCategories() {
      let result;
      try  {
        result = await dispatcher.getCategories(true);
        const cacheUpdateTime = Vue.$cookies.get('categories') ? moment(Vue.$cookies.get('categories').categoriesCacheLastUpdated) : null
        const responseUpdateTime = result.updated_at ? moment(result.updated_at) : null
        await this.fetchAdminCategories(cacheUpdateTime && responseUpdateTime && responseUpdateTime.diff(cacheUpdateTime, 'seconds') > 0)
      } catch (e) {
        this.SET_TEMP_ALERT({flavor: 'error', content: i18n.t('There was an error getting the categories. Please try again later')})
      }
    },

    async getAuctionDetail(auctionID) {
      try {
        this.loading = true;
        await this.getAdminArticles({ auctionID, auction: this.selectedAuctionData })
        let responseAuction = this.auctionsFetchedArticles[`auction_${auctionID}`]

        let auctionNumber = responseAuction.code
        let mediaServer = this.appLocalization.media_server_url

        responseAuction = responseAuction.map(itm => {
          let articleNumber = itm.number
          let imageUrl = `${mediaServer}/${auctionNumber}/${articleNumber}.jpg`
          return { ...itm, image: imageUrl }
        })

        this.articles = responseAuction
        this.currentAuctionId = auctionID;

        this.clearErrors()
        this.loading = false;
      } catch (e) {
        this.error = true;
        this.loading = false;
      }
    },

    clearErrors() {
      this.listArtcilesNoImages = []
      this.validateImageError = false
      this.dialog = false
    },

    openUpsertArticleModal(article) {
      if (this.$refs.upsertArticleModal) {
        this.$refs.upsertArticleModal.open(article)
      }
      if (article !== undefined && (!this.$route.query.articleNumber || this.$route.query.articleNumber.toString() !== article.number.toString())) this.$router.replace({ query: { articleNumber: article.number } })
    },

    async deleteArticle() {
      if (!this.currentDeleteArticle) {
        return;
      }

      try {
        this.loading = true;
        await dispatcher.removeArticle(this.currentAuctionId, this.currentDeleteArticle.id)
        this.articles = this.articles.filter(a => a.id !== this.currentDeleteArticle.id)
        this.loading = false;
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Article deleted successfully') })
      } catch (e) {
        this.error = true;
        if (e.data?.error) {
          this.SET_TEMP_ALERT({ flavor: 'error', content: `${this.$t('There was an error deleting the article')}\n${this.$t(e.data.data[0].errorShortText)}` })
        }
      }
      this.loading = false;
      this.currentDeleteArticle = null;
      this.$refs.dialog.close();
    },

    handleDeleteArticle(article) {
      try {
        this.currentDeleteArticle = article;
        if (article.status === '0' || article.status === '2') {
          this.openDialog(
            true,
            undefined,
            'alert',
            this.$t('Delete Article'),
            this.$t('You can not delete an article which is on auction or sold')
          );
        } else {
          this.openDialog(
            true,
            'deleteArticle',
            'confirm',
            this.$t('Delete Article'),
            this.$t('Are you sure to delete this article?')
          );
        }
      } catch (e) {
        console.log(e)
      }
    },

    handleImportArticle(newArticles, ...args) {
      this.newArticles = newArticles.map(i => ({
        ...i,
        auction_id: this.currentAuctionId
      }));
      this.openDialog(true, 'importArticles', ...args);
    },
    async importArticles() {
      this.cancelLabel = this.$t('Ok')
      if (this.newArticles && this.newArticles.length > 0) {
        try {
          this.loading = true;
          if (this.newArticles.length > 50) {
            const chunks = this.splitToChunks(50, this.newArticles)
            let result = []
            let errors = []
            const reduceResult = await chunks.reduce((previousPromise, nextChunk) => {
              return previousPromise.then((previousPromiseResult) => {
                if (previousPromiseResult && previousPromiseResult.articles) {
                  result = [...result, ...previousPromiseResult.articles]
                }
                if (previousPromiseResult && previousPromiseResult.error_articles) {
                  errors = [...errors, ...previousPromiseResult.error_articles]
                }
                return dispatcher.importArticles(this.currentAuctionId, nextChunk)
              });
            }, Promise.resolve());
            this.articles = [...result, ...reduceResult.articles]
            this.articleErrors = [...errors, ...reduceResult.error_articles]
            let errorMessage = ''
            this.articleErrors.forEach(el => {
              el.forEach(item => {
                for (let key in item.constraints) {
                  errorMessage += `Article ${item.target.number}: ${item.constraints[key]}\n`
                }
              })
            })
            if (errorMessage) {
              this.SET_TEMP_ALERT({ flavor: 'error', content: errorMessage})
            }
          } else {
            const result = await dispatcher.importArticles(this.currentAuctionId, this.newArticles)
            this.articles = result.articles
          }

          this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('All articles imported successfully') })
        } catch (e) {
          this.error = true;
          if (e.data?.error) {
            this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t(e.data.data[0].errorShortText) })
          }
        }
        this.loading = false;
        this.newArticles = null;
      }
    },

    splitToChunks (perChunk, inputArray) {
      return inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk)
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item)
        return resultArray
      }, [])
    },

    openDialog(isCancel = true, section, dialogType = 'confirm', ...args) {
      this.dialogFor = section;
      this.cancelLabel = this.$t('Cancel')
      if (isCancel === false) { this.cancelLabel = this.$t('Ok') }

      this.$refs.dialog.open(dialogType, ...args);
    },

    handleOk() {
      const actions = {
        deleteArticle: this.deleteArticle,
        importArticles: this.importArticles
      }

      if (actions[this.dialogFor]) actions[this.dialogFor]()
    },

    closeModal () {
      this.$router.push({ name: 'Auctions' })
    },

    async reFetchArticles() {
      await this.getAdminArticles({ auctionID: this.$route.params.id, auction: this.selectedAuctionData })
      this.knockdowns = await this.getAdminKnockdownsPerAuction(this.$route.params.id)
      this.articles = this.auctionsFetchedArticles[`auction_${this.$route.params.id}`]
    },

    async syncData() {
      this.currentAuctionId = this.$route.params.id
      await this.getAdminArticles({ auctionID: this.$route.params.id, auction: this.selectedAuctionData })
      this.knockdowns = await this.getAdminKnockdownsPerAuction(this.$route.params.id)
      this.articles = this.auctionsFetchedArticles[`auction_${this.$route.params.id}`]
    },
  }
}
</script>
<style lang="scss" scoped>
.auction-container {
  &.settings.auctions {
    .close-btn-wrapper {
      top: 110px;
    }
  }
}
</style>
