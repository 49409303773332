<template>
  <div class="auction-container settings mailing" v-if="currentConfig">
    <div class="close-btn-wrapper">
      <v-btn class="close-btn" @click="$router.go(-1)">
        <v-icon class="close-modal-icon">fa-times</v-icon>
      </v-btn>
      <v-btn class="send-btn" @click="saveEmailTemplates" :disabled="(!templateIsChanged && !dataIsChanged) || !saveEnabled || loading" :loading="loading">
        <v-icon class="save-modal-icon mr-1">fa-save</v-icon>
        <span>{{ $t('Save') }}</span>
      </v-btn>
    </div>
    <form-template
      :formData="generalFormData"
      @changeData="dataIsChanged = true"
      v-model="currentConfig.general"
    />
    <form-template
      :formData="formData"
      @changeData="dataIsChanged = true"
      v-model="currentConfig.mailing"
    >
      <div class="emailTestWrap emailTestWrapCentered">
        <v-btn
          class="btn primary-btn al-btn testBtn"
          @click="doTesting()"
          :disabled="loading || mailLang === null || !mailBody"
        >
          <span>{{ $t('Test Email') }}</span>
        </v-btn>
        <p class="warning-message float-right my-1">
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="loading"
          />
          <v-icon color="#4CAF50" v-if="!loading && status === ServerStatus.ok">fa-circle-check</v-icon>
          <v-icon color="#FF3232" v-if="!loading && status === ServerStatus.error">fa-circle-xmark</v-icon>
        </p>
        <p class="warning-message msg-error float-right mb-0" v-if="emailError">
          {{ emailError }}
        </p>
      </div>
    </form-template>
    <v-container>
      <div class="emailTestWrapCentered pb-16">
        <p>{{ $t('Mail content editor') }}</p>
        <v-col cols="9" class="px-0">
          <v-row>
            <v-col cols="6">
              <v-select
                class="input-field"
                :items="langItems"
                :label="$t('Language')"
                v-model="mailLang"
                item-text="name"
                item-value="lang"
                @input="selectTemplate"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                class="input-field"
                :items="templateItems"
                :label="$t('Email template')"
                v-model="mailTemplate"
                @change="selectTemplate"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-progress-circular v-if="templateLoading" class="loading-icon my-6" indeterminate />
        <div class="editorContent" v-else-if="mailTemplate && mailBody">
          <v-container>
            <v-text-field
              class="textarea-emailsetting"
              :label="$t('Email subject')"
              :value="mailBody.subject"
              @input="mailBody.subject = $event; templateIsChanged = true"
            />
            <v-textarea
              class="textarea-emailsetting"
              name="input-mail-1"
              :label="$t('Email contents')"
              rows="30"
              :value="mailBody.content"
              @input="mailBody.content = $event; templateIsChanged = true"
              no-resize
            />
          </v-container>
        </div>
      </div>
    </v-container>

    <confirmation-modal
      :title="$t('Please confirm')"
      :text="$t('Changes are not saved, do you really want to continue?')"
      @submit="submitChange"
      @cancel="cancelChange"
      ref="confirmationModal"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import FormTemplate from '@/components/form/index'
import ConfirmationModal from '@/components/modals/confirmation-modal'
import { mapActions, mapState } from 'pinia'
import useRootStore from '@/stores/rootStore';
import dispatcher from '@/api/dispatch';

const ServerStatus = {
  ok: 1,
  uncheck: 0,
  error: -1,
}

export default {
  name: 'MailingSetting',
  components: {
    FormTemplate,
    ConfirmationModal
  },
  data: function () {
    return {
      dataIsChanged: false,
      templateIsChanged: false,
      templateItems: [],
      mailBody: null,
      mailBodyDe: [
        {
          subject: 'Bid4It - Ihre Registrierung zum Live-Bieten',
          content: ''
        },
        {
          subject: 'Bid4It - Änderung Ihres Passwortes',
          content: ''
        },
        {
          subject: 'Bid4It - Account freigeschaltet',
          content: ''
        },
        {
          subject: 'Bid4It - Account konnte nicht freigeschaltet werden',
          content: ''
        },
        {
          subject: 'Bid4It - Passwort erfolgreich geändert',
          content: ''
        },
        {
          subject: 'Bid4It - Anmeldung zur kommenden Auktion',
          content: ''
        },
        {
          subject: 'Bid4It - Anfrage an das Auktionshaus',
          content: ''
        },
        {
          subject: 'Bid4It - Anfrage von Benutzer',
          content: ''
        },
        {
          subject: 'Bid4It - Artikelanfrage',
          content: ''
        },
        {
          subject: 'Bid4It - Artikelanfrage von Benutzer',
          content: ''
        },
        {
          subject: 'Bid4It - Vorgebote eingegangen',
          content: ''
        },
        {
          subject: 'Bid4It - Vorgebote angenommen',
          content: ''
        },
        {
          subject: 'Bid4It - Vorgebote abgelehnt',
          content: ''
        },
        {
          subject: 'Bid4It - Artikel auf der Wunschliste wird versteigert',
          content: ''
        },
        {
          subject: 'Bid4It - Nachverkaufsanfrage',
          content: ''
        },
        {
          subject: 'Bid4It - Nachverkaufsanfrage von Benutzer',
          content: ''
        }
      ],
      langItems: [],
      mailLang: 'en',
      savedMailLang: 'en',
      mailTemplate: null,
      savedCurrentTab: null,
      message: null,
      emailError: '',
      currentConfig: null,
      loading: null,
      ServerStatus,
      status: ServerStatus.uncheck,
      generalFormData: [
        {
          type: 'email',
          label: this.$t('E-mail address auction house'),
          key: 'email_auction_house',
        },
        {
          type: 'email',
          label: this.$t('E-mail address platform provider'),
          key: 'email_provider',
        },
      ],
      formData: [
        {
          type: 'text',
          label: this.$t('Mail server'),
          key: 'mail_server',
          required: true
        },
        {
          type: 'number',
          label: this.$t('Port'),
          key: 'port',
          required: true
        },
        {
          type: 'text',
          label: this.$t('Username'),
          key: 'username',
        },
        {
          type: 'password',
          label: this.$t('Password'),
          key: 'password',
        },
        {
          type: 'text',
          label: this.$t('From name'),
          option: true,
          key: 'from_name',
        },
        {
          type: 'text',
          label: this.$t('BCC'),
          option: true,
          key: 'email_bcc',
        },
        {
          type: 'text',
          label: this.$t('Test email receiver'),
          key: 'test_email_receiver',
        },
        {
          type: 'button',
          key: 'send_test'
        },
      ],
      templateLoading: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['appLocalization']),
    selectedTabIndex() {
      return this.templateItems.length && this.templateItems.findIndex(el => el.value === this.mailTemplate)
    },
    saveEnabled () {
      return this.currentConfig.mailing.mail_server && this.currentConfig.mailing.port
    }
  },
  async created() {
    await this.getUserSettings()
    await this.getEmailTemplates()
    this.langItems = this.appLocalization.i18n.availableLanguages
  },
  methods: {
    ...mapActions(useRootStore, ['getUserSettings', 'SET_TEMP_ALERT']),
    async getEmailTemplates() {
      try {
        this.loading = true;
        let lang = this.mailLang.charAt(0).toUpperCase() + this.mailLang.slice(1)
        const result = await dispatcher.getConfigFields('mailing,general')
        this.currentConfig = _.clone(result);
        const localizationData = result.mailing[`mailingSubjects${lang}`]
        // console.log(Object.keys(localizationData))
        Object.keys(localizationData).forEach(key => {
          this.templateItems.push({ text: this.$t(localizationData[key]), value: key })
        })

        this.loading = false;
      } catch (e) {
        this.error = true;
        this.loading = false;
      }
    },
    async saveConfigs() {
      try {
        this.loading = true
        await dispatcher.updateConfigs(this.currentConfig)
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Values changed successfully') })
        this.dataIsChanged = false
        this.loading = false
      } catch (e) {
        this.error = true;
        this.dataIsChanged = true
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error changing values') })
        this.loading = false;
      }
    },
    async doTesting() {
      try {
        this.loading = true
        this.emailError = null
        await dispatcher.sendTestEmail({
          email: this.currentConfig.mailing.test_email_receiver,
          lang: this.mailLang,
          template: this.mailTemplate
        })
        this.loading = false;
        this.status = ServerStatus.ok
      } catch (e) {
        this.error = true;
        this.status = ServerStatus.error;
        this.loading = false;
        if (e.data?.error) {
          this.emailError = this.$t(e.data.data[0].errorShortText);
        }
      }
    },
    async saveEmailTemplates() {
      try {
        this.loading = true;
        if (this.templateIsChanged) {
          await dispatcher.updateEmailTemplate({ name: this.mailTemplate, lang: this.mailLang, subject: this.mailBody.subject, content: this.mailBody.content })
          this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Mail content changed successfully') })
          this.templateIsChanged = false
        }
        if (this.dataIsChanged) await this.saveConfigs()
        this.loading = false;
      } catch (e) {
        this.error = true;
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error changing mail content') })
        this.loading = false;
      }
    },
    selectTemplate () {
      if (this.templateIsChanged) {
        this.$refs.confirmationModal.openModal()
        return
      }
      this.getTemplateData()
    },
    cancelChange() {
      this.mailTemplate = this.savedCurrentTab
      this.mailLang = this.savedMailLang
      this.$refs.confirmationModal.closeModal()
    },
    async getTemplateData() {
      try {
        if (this.mailTemplate === null) {
          return
        }
        this.templateLoading = true
        this.savedCurrentTab = this.mailTemplate
        this.savedMailLang = this.mailLang
        const result = await dispatcher.getEmailTemplate(this.mailTemplate, this.mailLang)
        this.mailBody = {
          subject: this.templateItems[this.selectedTabIndex].text,
          content: result
        }
        this.templateIsChanged = false
        this.templateLoading = false
      } catch (e) {
        this.templateLoading = false
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting the mail template. Please try again later') })
      }
    },
    async submitChange() {
      await this.getTemplateData()
      this.$refs.confirmationModal.closeModal()
    }
  },
}
</script>
