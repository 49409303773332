<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <v-card class="user-profile-container user-catalogue">
      <div class="back-btn-wrapper" @click="signInAccount">
        <v-icon>fa-angle-left</v-icon>
      </div>
      <v-card-text class="main-card">
        <v-container>
          <div>
            <v-row align="center" class="form-container justify-center flex-column">
              <v-col sm="12" class="full-width">
                <h2 class="page-title text-center">
                  {{ modalTitle }}
                </h2>
                <v-col class="main-col-wrapper">
                  <v-col class="sub-col-wrapper">
                    <v-row>
                      <div v-html="modalBody" class="static-page-text" />
                    </v-row>
                  </v-col>
                </v-col>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import xml2js from 'xml2js'
import { mapState } from 'pinia'
import useRootStore from '@/stores/rootStore'
import dispatcher from '@/api/dispatch';

export default {
  name: 'InfoView',
  data: () => ({
    modalTitle: '',
    modalBody: '',
    loading: false
  }),
  mounted() {
    this.showStaticPage('terms')
  },
  computed: {
    ...mapState(useRootStore, ['appStoreLang']),
  },
  methods: {
    signInAccount() {
      this.$router.push({ name: 'login' })
    },
    async showStaticPage(pageName) {
      try {
        this.loading = true
        // To prevent large load  - EN
        let xmlResponse = await dispatcher.getStaticPage(pageName, this.appStoreLang || this.$i18n.locale)
        xml2js.parseString(xmlResponse, (err, result) => {
          if (!err) {
            this.modalTitle = result.page.heading.toString()
            this.modalBody = result.page.body.toString()
          } else {
            throw err;
          }
        });
        this.loading = false
      } catch (e) {
        this.error = true
        this.loading = false
      }
    },
  },
  watch: {
    appStoreLang() {
      this.showStaticPage('terms')
    }
  },
}
</script>
<style scoped lang="scss">

</style>
